import {AbstractView} from '../core/AbstractView';
import {MouseEvent} from '../../../lib/com/hellomonday/events/MouseEvent';
import {Linear, TweenMax} from "gsap/TweenMax";
import * as THREE from "three";
import {Globals} from "../utils/Globals";

export class Ball {

	private ball: THREE.Group;
	private ballWidth: number = 0.02;

	private fireballShaderMaterial: THREE.ShaderMaterial;
	private pointLight: THREE.PointLight;

	private fireballMesh;

	public raycastLine: any;


	constructor() {
		this.init();
	}

	private init() {
		this.ball = new THREE.Group();

		// Swap for Debug Box
		//var ballGeometry = new THREE.BoxGeometry(this.ballWidth, this.ballWidth, this.ballWidth);
		var ballGeometry = new THREE.SphereBufferGeometry(this.ballWidth, 10, 10);

		var ballMaterial = new THREE.MeshPhongMaterial({color: Globals.colorAccentLight});
		var actualBall = new THREE.Mesh(ballGeometry, ballMaterial); // this.createLight(0xfc8228);//
		actualBall.position.y = this.ballWidth;
		//this.ball.add(actualBall);

		this.ball.visible = false;

		//	var raycastGeo = new THREE.BoxBufferGeometry(this.ballWidth / 2, this.ballWidth, this.ballWidth * 10);

		//	var raycastMat = new THREE.MeshPhongMaterial({color: Globals.colorAccentLight});
		//	this.raycastLine = new THREE.Mesh(raycastGeo, raycastMat); // this.createLight(0xfc8228);//

		//this.raycastLine.position.z = this.ballWidth * -5;
		//	this.ball.add(this.raycastLine);

		this.addFireball();
	}

	private addFireball() {
		var bkgScene = new THREE.Scene();
		var bkgShader = new THREE.ShaderMaterial({

			uniforms: {
				resolution: {type: "v2", value: new THREE.Vector2(window.innerWidth, window.innerHeight)}
			},
			vertexShader: document.getElementById('vertexShader').textContent,
			fragmentShader: document.getElementById('fs_Gradient').textContent,
			depthWrite: false,
			depthTest: false
		});

		var quad = new THREE.Mesh(new THREE.PlaneBufferGeometry(1, 1), bkgShader);
		bkgScene.add(quad);

		this.fireballShaderMaterial = new THREE.ShaderMaterial({
			uniforms: {
				tExplosion: {type: "t", value: Globals.getNamedTexture('fire')},
				time: {type: "f", value: 0.0},
				weight: {type: "f", value: 10.0}
			},
			vertexShader: document.getElementById('vertexShader').textContent,
			fragmentShader: document.getElementById('fragmentShader').textContent
		});
//Buffer
		var mesh = new THREE.Mesh(new THREE.IcosahedronGeometry(20, 5), this.fireballShaderMaterial);

		mesh.position.y = this.ballWidth;
		mesh.castShadow = true;

		this.fireballMesh = mesh;

		// Auto Rotate
		TweenMax.to(this.ball.rotation, 10, {z: '+=' + THREE.Math.degToRad(360), repeat: -1, yoyo: true})
		this.ball.add(mesh);


		// distance: 1
		this.pointLight = new THREE.PointLight(Globals.colorAccentLight, 0);
		this.pointLight.castShadow = true;
		this.pointLight.position.y = 1;
		this.ball.add(this.pointLight)
	}

	public getBall() {
		return this.ball;
	}

	public getBallSize(): number {
		return this.ballWidth;
	}

	public updateShader(startTime: number) {
		if (this.ball.visible) {
			this.fireballShaderMaterial.uniforms['time'].value = .00035 * (Date.now() - startTime); // .00025
		}
	}

	public getLight() {
		return this.pointLight
	}
}
