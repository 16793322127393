import { AbstractModule } from '../../lib/com/hellomonday/core/AbstractModule';
import { AbstractView } from '../views/core/AbstractView';

export class TestModule extends AbstractModule {
	private test: HTMLElement;

	constructor(element: HTMLElement, view: AbstractView) {
		super(element, view);
	}

	resize() {
		super.resize();
	}
}
