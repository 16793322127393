import {AbstractView} from '../core/AbstractView';
import {MouseEvent} from '../../../lib/com/hellomonday/events/MouseEvent';
import {Linear, TweenMax} from "gsap/TweenMax";
import * as THREE from "three";
import {Globals} from "../utils/Globals";

export class Rain {

	private cloudParticles = [];
	private flash;
	private rain;
	private rainGeo;
	private rainCount = 1500;
	private rainGroup: THREE.Group = new THREE.Group;

	private cloudMesh: any;

	private allowFlashing:Boolean = true;

	private _mainSceneGroup;

	constructor(mainSceneGroup) {
		this._mainSceneGroup = mainSceneGroup;
		this.init();
	}

	private init() {
//0x062d89
		this.flash = new THREE.PointLight(0x444444, 1, 2000, 1.7);
		this.flash.position.set(0, 0, -990);

		this.rainGroup.add(this.flash);

	//	var directionalLight = new THREE.DirectionalLight(0xcccccc, 0.1);
	//	directionalLight.position.set(0,0,1);
	//	this.rainGroup.add(directionalLight);


	/*	this.rainGeo = new THREE.Geometry();
		for (let i = 0; i < this.rainCount; i++) {
			let rainDrop = new THREE.Vector3(
				Math.random() * 20 - 10,
				Math.random() * 20 - 10,
				Math.random() * 10 - 10
			);
			rainDrop.velocity = {};
			rainDrop.velocity = 0;
			this.rainGeo.vertices.push(rainDrop);
		}
		var rainMaterial = new THREE.PointsMaterial({
			color: 0xaaaaaa,
			size: 0.1,
			opacity: 0.3,
			transparent: true
		});
		this.rain = new THREE.Points(this.rainGeo, rainMaterial);*/
		//this._mainSceneGroup.add(this.rain);

		this.smokeLoaded();
	}

	private smokeLoaded = () => {
		var texture = Globals.getNamedTexture('smoke');
		var cloudGeo = new THREE.PlaneBufferGeometry(320, 320, 1, 1);

		var cloudMaterial = new THREE.MeshLambertMaterial({
			color: 0xbbbbbb,
			map: texture,
			transparent: false,
			flatShading: false,
			reflectivity: 0,
			emissive: 0x000000

		});

		cloudMaterial.fog = false;
	//	for (let p = 0; p < 1; p++) {
			let cloud = new THREE.Mesh(cloudGeo, cloudMaterial);
			cloud.position.set(
				0,
				0,
				-1000
			);

			cloud.rotation.z = Math.random() * 360;
			this.cloudParticles.push(cloud);
			this.rainGroup.add(cloud);

			this.cloudMesh = cloud;
	//	}
	};


	public animate = (mainSceneGroup) => {
		//return;

		if (mainSceneGroup)
		{

			var currentCameraPos = mainSceneGroup.position.z;
		//	console.log(currentCameraPos)

			var scaleFactor = ((1000 -currentCameraPos) / 1000) * 6 + 1;
			this.cloudMesh.scale.x = scaleFactor;
			this.cloudMesh.scale.y = scaleFactor;
			this.cloudMesh.scale.z = scaleFactor;
			//console.log('scaleFactor : ' + scaleFactor)


		/*	var scaleFactor = ((1000 - currentCameraPos) / 1000) * 10 + 1;
			this.rainGroup.position.x = scaleFactor;
			this.rainGroup.position.y = scaleFactor;
			this.rainGroup.position.z = scaleFactor;*/

	//		console.log('scaleFactor : ' + scaleFactor)


				//-currentCameraPos;
			//	console.log('this.cloudMesh.position.z : ' + this.cloudMesh.position.z)


		}


		this.cloudParticles.forEach(p => {
			p.rotation.z -= 0.002;

			// Force the planes to always look directly at the camera
		//	p.quaternion.copy( Globals.mainScene.getCamera().quaternion );
		});
	/*	this.rainGeo.vertices.forEach(p => {
			p.velocity -= 0.0001 + Math.random() * 0.002;
			p.y += p.velocity;
			if (p.y < -5) {
				p.y = Math.random() * 5 + 13;
				p.velocity = 0;
			}
		});
		this.rainGeo.verticesNeedUpdate = true;*/
		//this.rain.rotation.x += 0.002;

		if (this.allowFlashing === true)
		{
			//console.log(this.flash.power)
			if (Math.random() > 0.99 || this.flash.power > 100) {
			//	console.log('FLASH!')
				if (this.flash.power < 100) {
					this.flash.position.set(Math.random() * 400 - 200, 300 + Math.random() * 200 - 400,	-700);
				}
				this.flash.power = (50 + Math.random() * 500) / 3;
			}
		}
		else {
			this.flash.power = 0;
		}
	}

	public stopFlashing() {
		this.allowFlashing = false;
	}

	public startFlashing() {
		this.allowFlashing = true;
	}

	public getMesh() {

		return this.rainGroup;
	}

	public getCloudMesh() {
		return this.cloudMesh;
	}

}
