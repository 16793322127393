import {Globals} from '../utils/Globals';
import * as THREE from "three";
import {TweenMax, Power1, Power2, Power3, Circ} from "gsap/TweenMax";

(window as any).THREE = THREE;

//@ts-ignore: Using Require to import ES5
require('./../TessellateModifier.js');

//@ts-ignore: Using Require to import ES5
let Howler = require('./../howler.js');

export class FloatingIslands {

	private flyingIslandScene;
	private rockObjects;
	private currentRock;

	private _mainSceneGroup;

	private _ballStoreData = {position: 0, scale: 0}

	constructor(mainSceneGroup) {
		this._mainSceneGroup = mainSceneGroup;
		this.init();
	}

	private init() {
		this.flyingIslandScene = this._mainSceneGroup; //new THREE.Group();
		this.rockObjects = [];


		var eMap = Globals.getNamedTexture('rock10e');
		var nMap = Globals.getNamedTexture('rock10n');
		var dMap = Globals.getNamedTexture('rock10d');

		var storeMaterial = new THREE.MeshStandardMaterial({
			flatShading: false,
			map: eMap,
			normalMap: nMap,
			metalness: 0.2,
			roughness: 0.7,
			color: 0x404040,
			bumpMap: dMap,
			bumpScale: 0.7
		});

		for (var i = 0; i < 13; i++) {
			var getRockObject = Globals.getNamedObject('rocksmall').clone();

			var getMainRock = getRockObject.children[getRockObject.children.length - 1];
			var getCenter = this.getCenterPoint(getMainRock);

			// FIXME - Needs to be put out of a loop - and into a single call so we dont re-create the object
			for (var p = 0; p < getRockObject.children.length; p++) {
				var currentObject = getRockObject.children[p];

				if (currentObject.isMesh) {
					currentObject.material = storeMaterial;
					currentObject.material.needsUpdate = true;// = THREE.SmoothShading;
					currentObject.geometry = new THREE.Geometry().fromBufferGeometry(currentObject.geometry);
					currentObject.geometry.mergeVertices();
					currentObject.geometry.computeVertexNormals();
					currentObject.geometry = new THREE.BufferGeometry().fromGeometry(currentObject.geometry);
					//currentObject.visible = false;

					var getCenterInnerRock = this.getCenterPoint(currentObject);


					var distanceToCenterX = getCenterInnerRock.x - getCenter.x;
					var distanceToCenterY = getCenterInnerRock.y - getCenter.y;
					var distanceToCenterZ = getCenterInnerRock.z - getCenter.z;

					currentObject.userData = {
						distanceToCenterX: distanceToCenterX,
						distanceToCenterY: distanceToCenterY,
						distanceToCenterZ: distanceToCenterZ,
					}

				}

				if (p === getRockObject.children.length - 1) {

				} else {
					currentObject.visible = false;
				}
			}

			//getRockObject.rotation.x = THREE.Math.degToRad(360);
			getRockObject.rotation.y = THREE.Math.degToRad(360);

			var specificDetailsForRock = [

				{scale: {x: 1.4, y: 1, z: 1}, rotation: {x: 60, y: 90, z: 90}, position: {x: -0.24, y: -0.3, z: 0}},
				{scale: {x: 1.2, y: 1, z: 1}, rotation: {x: 30, y: 20, z: 50}, position: {x: -1, y: 3, z: 0}},
				{scale: {x: 1.5, y: 1, z: 1}, rotation: {x: 0, y: 0, z: 40}, position: {x: -3, y: -1, z: 0}},
				{scale: {x: 1.1, y: 1, z: 1}, rotation: {x: 1800, y: 0, z: 120}, position: {x: 2, y: -1, z: 0}},
				{scale: {x: 2, y: 1, z: 1}, rotation: {x: 50, y: 0, z: 190}, position: {x: -1, y: -2, z: 0}},

				{scale: {x: 1.4, y: 1, z: 1}, rotation: {x: 20, y: 0, z: 100}, position: {x: -0.3, y: -0.4, z: 600}},
				{scale: {x: 1.1, y: 1, z: 1}, rotation: {x: 0, y: 700, z: 20}, position: {x: 3, y: 3, z: 0}},


				{scale: {x: 1.2, y: 1, z: 1}, rotation: {x: 270, y: 1600, z: 140}, position: {x: 2, y: -1, z: 0}},


				{scale: {x: 1.5, y: 1, z: 1}, rotation: {x: 350, y: 60, z: 250}, position: {x: 1, y: -3, z: 0}},
				{scale: {x: 1.1, y: 1, z: 1}, rotation: {x: 20, y: 100, z: 100}, position: {x: -0.5, y: -0.2, z: 600}},
				{scale: {x: 1, y: 1, z: 1}, rotation: {x: 0, y: 20, z: 20}, position: {x: 3, y: 3, z: 0}},


				{scale: {x: 2, y: 1.3, z: 1.5}, rotation: {x: 30, y: 160, z: 140}, position: {x: 0.3, y: -2, z: 0}},
				{scale: {x: 1, y: 1.6, z: 1.2}, rotation: {x: 0, y: 0, z: 60}, position: {x: -2, y: 1, z: 0}},


			];

			var rockDetails = specificDetailsForRock[i];

			var addToScale = 20;
			getRockObject.scale.set(rockDetails.scale.x * addToScale, rockDetails.scale.y * addToScale, rockDetails.scale.z * addToScale);

			getRockObject.rotation.x = THREE.Math.degToRad(rockDetails.rotation.x);
			getRockObject.rotation.y = THREE.Math.degToRad(rockDetails.rotation.y);
			getRockObject.rotation.z = THREE.Math.degToRad(rockDetails.rotation.z);

			getRockObject.position.z = i * -60 - 200;
			getRockObject.position.y = rockDetails.position.y * 50;
			getRockObject.position.x = rockDetails.position.x * 50;

			this.rockObjects.push(getRockObject);
			this.flyingIslandScene.add(getRockObject);

			// Make the objects float a bit
			/*	*/

		}
	}

	public animateAllRocks() {
		for (var i = 0; i < this.rockObjects.length; i++) {
			var getRockObject = this.rockObjects[i];
			this.animateRock(getRockObject);
		}
	}


	private animateRock = (rockObject) => {
		TweenMax.to(rockObject.position, Math.random() * 20 + 10, {
			delay: 0,
			x: rockObject.position.x + 50 + Math.random() * -100,
			y: rockObject.position.y + 50 + Math.random() * -100,
			z: rockObject.position.z + 20 + Math.random() * -10,
			yoyo: true,
			repeat: -1,
			ease: Power2.easeInOut,
			onCompleteParams: [rockObject],
			onComplete: this.animateRock
		});
	}

	public flyToObject = (rockNumber, fireball, placeBallBack) => {

		if (placeBallBack) {

			/*Globals.mainScene.getScene().add(fireball);

			//fireball.position.set(this._ballStoreData.position.x, this._ballStoreData.position.y, this._ballStoreData.position.z + 40);
			fireball.position.set(0, 0, 0 - 5);

			 this._ballStoreData.scale = 0.10;
			fireball.scale.set(this._ballStoreData.scale, this._ballStoreData.scale, this._ballStoreData.scale);



			//fireball.position.x = 0;
			//fireball.position.y = 0;
			console.log(fireball)*/
		}

		Globals.rain.startFlashing();


		this.currentRock = this.rockObjects[rockNumber];
		//console.log(this.currentRock.position)
		TweenMax.to([this.flyingIslandScene.position], 3.1 * Globals.speedUpFloatingIslandWith, {
			x: this.currentRock.position.x * -1,
			z: this.currentRock.position.z * -1 - 50,
			y: this.currentRock.position.y * -1,
			onComplete: this.zoomIntoIsland,
			onCompleteParams: [fireball],
			ease: Circ.easeIn
		});


		var getMainRock = this.currentRock.children[this.currentRock.children.length - 1];
		//console.log(getMainRock)

		var getCenter = this.getCenterPoint(getMainRock);
		getMainRock.localToWorld(getCenter);
		//console.log(this.currentRock.position)
	//	console.log(getCenter)
		TweenMax.to(fireball.position, 3.1 * Globals.speedUpFloatingIslandWith, {
			x: getCenter.x,
			y: getCenter.y,
			z: getCenter.z - 2,
			ease: Circ.easeIn
		});

		this._ballStoreData.position = new THREE.Vector3(getCenter.x, getCenter.y, getCenter.z - 2);
		this._ballStoreData.scale = fireball.scale.x;

	};

	private getCenterPoint = (mesh) => {
		var geometry = mesh.geometry;
		geometry.computeBoundingBox();
		var useCenter = new THREE.Vector3();
		var center = geometry.boundingBox.getCenter(useCenter);
		//mesh.localToWorld( center );
		return center;
	}


	private zoomIntoIsland = (fireball) => {
		//this.currentRock.children[10].visible = false;
		var sound = new Howl({
			src: ['assets/sounds/distant-explosions-with-mid-rumble.mp3']
		});
		sound.play();

		//TweenMax.to(Globals.scaleMusicFactor, 0.3, {delay: 3, factor: 100, ease: Circ.easeIn, onComplete: this.splashedOut});
		var getMainRock = this.currentRock.children[this.currentRock.children.length - 1];
		var getCenter = this.getCenterPoint(getMainRock);

		// We are hiding the main rock
		for (var i = 0; i < this.currentRock.children.length - 1; i++) {
			var getRock: THREE.Mesh = this.currentRock.children[i];

			var moveOutWards = 50;

			TweenMax.to(getRock.position, 25 * Globals.speedUpFloatingIslandWith, {
				x: getRock.userData.distanceToCenterX * moveOutWards,
				y: getRock.userData.distanceToCenterY * moveOutWards,
				z: getRock.userData.distanceToCenterZ * moveOutWards, ease: Power1.easeOut
			});

			getRock.visible = true;
		}
		this.currentRock.children[this.currentRock.children.length - 1].visible = false;

		Globals.mainScene.startGame();

	};

	public getMesh() {
		return this.flyingIslandScene;
	}
}
