import * as THREE from 'three';
import {Mask} from "./Mask";
import {Circ, Linear, Quad, TweenMax, Power1, Power2, Power3} from "gsap/TweenMax";
import {Ball} from "../game/Ball";
import {Player} from "../game/Player";
import {Brick} from "../game/Brick";
import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";
import {Globals} from "../utils/Globals";
import {PointLight} from "three";

(window as any).THREE = THREE;


//@ts-ignore: Using Require to import ES5
require('./../OBJLoader.js');

/*
//@ts-ignore: Using Require to import ES5
require('./SubdivisionModifier.js');
//@ts-ignore: Using Require to import ES5
require('./TessellateModifier.js');
//@ts-ignore: Using Require to import ES5
require('./OBJLoader.js');
//@ts-ignore: Using Require to import ES5
require('./MTLLoader.js');*/

export class Skull {

	private newGameCallback;

	private sceneGroup: THREE.Group;

	private skullObject: THREE.Group;

	private skullHead: THREE.Mesh;
	private skullJaw: THREE.Mesh;

	private defaultSkullScale: number = 0.01;
	//private defaultSkullScale: number = 0.06;

	private pointLight: PointLight;

	private status: string = 'selection';

	private fireballShaderMaterial: THREE.ShaderMaterial;
	private fireBallPointLight: THREE.PointLight;
	private ball: any;

	private onSelectionCallback;

	private backSideBorder;
	private allBricksRemoved: boolean = false;

	private _allowAnimation: boolean = false;

	private mouthOpenCallback;
	private onMouthOpenIntroCallback;
	private _previousPercentageMouthOpened: number = 0;

	private countClicks: number = 0;

	constructor(callback) {
		this.onMouthOpenIntroCallback = callback;
		this.init();
	}

	private init = () => {
		this.sceneGroup = new THREE.Group();
		this.ball = Globals.ball.getBall();
		this.ball.scale.set(0.01, 0.01, 0.01);
		this.ball.position.z = -1;
		this.ball.position.y = -0.7;
		this.ball.castShadow = true;
		this.sceneGroup.add(this.ball);

		this.skullObject = Globals.getNamedObject('skullBig');
		this.skullHead = this.skullObject.children[0];
		this.skullHead.rotation.x = 0;
		this.skullJaw = this.skullObject.children[1];
		this.skullJaw.rotation.x = 0;
		this.setMaterials(this.skullObject);

		//this.addFireball();
		//	this.rainFire();
	};

	private rainFire() {
		for (var i = 0; i < 20; i++) {
			var addPlane = new THREE.PlaneGeometry(0.1, 0.1);
			var planeMaterial = new THREE.MeshPhongMaterial({color: 0x000000});
			var planeMesh = new THREE.Mesh(addPlane, planeMaterial);

			var pointLight = new THREE.SpotLight(Globals.colorAccentLight, 0.2, 20, 0.5, 0, 0);
			pointLight.position.z = -0.4;

			planeMesh.add(pointLight);

			planeMesh.position.set(Math.random() * -4 - 4, Math.random() * 2 + 2, Math.random() * 10 - 5);

			TweenMax.to(planeMesh.position, Math.random() * 1 + 0.5, {
				delay: Math.random() * 1,
				x: 5,
				y: -2,
				ease: Linear.easeNone,
				onComplete: this.resetFire,
				onCompleteParams: [planeMesh]
			});
			TweenMax.to(planeMesh.rotation, 1.5, {
				delay: 0,
				x: Math.random() * 4 + 10,
				y: Math.random() * 4 + 10,
				z: Math.random() * 4 + 10,
				ease: Linear.easeNone
			});


			this.sceneGroup.add(planeMesh);
		}
	}

	private resetFire = (planeMesh) => {
		planeMesh.position.set(Math.random() * -4 - 4, Math.random() * 2 + 2, Math.random() * 10 - 5);

		TweenMax.to(planeMesh.position, Math.random() * 1 + 0.5, {
			delay: Math.random() * 1,
			x: 5,
			y: -2,
			ease: Linear.easeNone,
			onComplete: this.resetFire,
			onCompleteParams: [planeMesh]
		});
		TweenMax.to(planeMesh.rotation, Math.random() * 1 + 0.5, {
			delay: 0,
			x: Math.random() * 4 + 10,
			y: Math.random() * 4 + 10,
			z: Math.random() * 4 + 10,
			ease: Linear.easeNone
		});
	};

	private setMaterials = (object) => {
		//	object.castShadow = true;
		//	object.receiveShadow = true;
		//TweenMax.to(object.rotation, 6, {y: 6})
		// For any meshes in the model, add our material.

		var map = Globals.getNamedTexture('skullBase');
		//var roughnessMap = Globals.getNamedTexture('skullRoughness');
		//var metalMap = Globals.getNamedTexture('skullMetallic');

		var normal = Globals.getNamedTexture('skullNormal');
		var aoMap = Globals.getNamedTexture('skullAO');

		var a = new THREE.Vector2(3, 3);
		//map.repeat.set(4, 4);
		/*var material = new THREE.MeshPhysicalMaterial({
			flatShading: false,
			color: 0x111111,
			map: map,
			normalMap: normal,
			normalScale: a,
			aoMap: aoMap,
			aoMapIntensity: 1,
			roughness: 0.65,
			metalnessMap: metalMap,
			metalness: 0.9,
			reflectivity: 0,
			refractionRatio: 0

		});
		*/

		var material = new THREE.MeshPhongMaterial({
			flatShading: false,
			color: 0x111111,
			map: map,
			normalMap: normal,
			normalScale: a,
			aoMap: aoMap,
			aoMapIntensity: 1,



			reflectivity: 0,
			refractionRatio: 0

		});

		//metalness: 0.9,
		//	metalnessMap: metalMap,
		//		roughness: 0.65,
		/*
			normalMapType: THREE.ObjectSpaceNormalMap
			roughnessMap: roughnessMap,
		,
			envMap: map*/

		object.traverse(function (node) {
			if (node.isMesh) {
				node.material = material;
				node.material.needsUpdate = true;// = THREE.SmoothShading;
				node.geometry = new THREE.Geometry().fromBufferGeometry(node.geometry);
				node.geometry.mergeVertices();
				node.geometry.computeVertexNormals();
				node.geometry = new THREE.BufferGeometry().fromGeometry(node.geometry);
			}
		};

		object.scale.set(this.defaultSkullScale, this.defaultSkullScale, this.defaultSkullScale);
		object.position.z = -1.5;
		object.position.y = -0.5;
		this.sceneGroup.add(object);

		var light1 = new THREE.SpotLight(0xffffff, 6, 200, 2, 0.3, 2);
		light1.position.set(-15, 10, 5);
		light1.lookAt(object.position)
		//	this.sceneGroup.add(light1);

		this.pointLight = new THREE.SpotLight(0xffffff, 16, 350, 2, 0.3, 2);
		this.pointLight.position.set(2, 2, 7);
		this.pointLight.lookAt(object.position)
		//	this.sceneGroup.add(this.pointLight);

		var light2 = new THREE.SpotLight(0xffffff, 6, 200, 2, 0.3, 2);
		light2.position.set(15, 12, 5);
		light2.lookAt(object.position)
		this.sceneGroup.add(light2);

		this.sceneGroup.position.x = 0;
	};

	public animate(startTime: number, runWithZeoTime) {
		if (this._allowAnimation) {

			var getFaceX = Globals.faceXPositionSmoothed;

			var getMouthOpen = Globals.faceMouthOpenPercentageSmoothed;
			getFaceX = getFaceX - 0.5;

			var easeType = Linear.easeNone;
			var duration = 0.2;

			if (runWithZeoTime === true)
			{
				duration = 0;
			}

			// Used at the intro for the selection screen
			if (this.status === 'selection') {

				if (Globals.skipSkeletonInIntro === true) {
						getMouthOpen = 1;
				}
				TweenMax.to(this.sceneGroup.rotation, duration, {
					y: getFaceX * 2 * (1 - getMouthOpen),
					ease: easeType
				});
				TweenMax.to(this.sceneGroup.position, duration, {
					x: getFaceX * 15 * (1 - getMouthOpen),
					ease: easeType
				});

				// Open Close Mouth
				TweenMax.to(this.skullHead.rotation, duration, {
					x: 0 + getMouthOpen * 0.4,
					ease: easeType
				});
				TweenMax.to(this.skullJaw.rotation, duration, {
					x: 0 - getMouthOpen * 0.3,
					ease: easeType
				});

				// Zoom Into the mouth
				var scaleTo = this.defaultSkullScale + getMouthOpen * 0.02;
				TweenMax.to(this.skullObject.scale, duration, {
					x: scaleTo,
					y: scaleTo,
					z: scaleTo,
					ease: easeType
				});

				// Move the mouth upwards when zooming in
				TweenMax.to(this.skullObject.position, duration, {
					y: getMouthOpen * 2 - 0.5,
					ease: easeType
				});

				// Increase ball light intensity
				var newIntensity = 10 * getMouthOpen;
				TweenMax.to(Globals.ball.getLight(), duration, {
					intensity: newIntensity,
					ease: easeType
				});

				// Increase the size of the ball
				var scaleBallTo = 0.02 * getMouthOpen + 0.01;
				TweenMax.to(this.ball.scale, duration, {
					x: scaleBallTo, y: scaleBallTo, z: scaleBallTo,
					ease: easeType
				});

				if (getMouthOpen >= 1 || Globals.skipSkeletonInIntro === true) {

					Globals.instructionText.animateOut();

					//@ts-ignore:
					window['ga']('send', 'event', 'Head Movement', 'Intro screen - Open Mouth');

					var sound = new Howl({
						src: ['assets/sounds/screaming-nightmare-passing.mp3']
					});

					sound.play();

					var sound = new Howl({
						src: ['assets/sounds/enolas_dead.mp3']
					});
					sound.volume(0.1);
					sound.play();

					this.zoomedIntoSkull();

					//TweenMax.delayedCall(0.1, this.difficultySelected);
				}

			} else if (this.status === 'startGame') {

				// Rotate the skull
				TweenMax.to([this.skullObject.rotation, Globals.ball.getBall().rotation], duration, {
					y: getFaceX * 2,
					ease: easeType
				});

				// Open Close
				TweenMax.to(this.skullHead.rotation, duration, {
					x: 0 + getMouthOpen * 0.4,
					ease: easeType
				});
				TweenMax.to(this.skullJaw.rotation, duration, {
					x: 0 - getMouthOpen * 0.3,
					ease: easeType
				});

				/*	var scaleTo = this.defaultSkullScale * 2 + Globals.faceMouthOpenPercentageTweened.x * 0.02;
                    TweenMax.to(this.skullObject.scale, duration, {
                        x: scaleTo,
                        y: scaleTo,
                        z: scaleTo,
                        ease: easeType
                    });/*

                 */

				// Ball light intensity
				var newIntensity = 5 * getMouthOpen + 0.5;
				TweenMax.to(Globals.ball.getLight(), duration, {
					intensity: newIntensity,
					ease: easeType
				});


				var cameraPositionDuration = duration;
				/*if (this._previousPercentageMouthOpened < Globals.faceMouthOpenPercentage) {
                    //cameraPositionDuration = 5.8;
                    console.log('this._previousPercentageMouthOpened : ' + this._previousPercentageMouthOpened)
                    console.log('Globals.faceMouthOpenPercentage : ' + Globals.faceMouthOpenPercentage)
                }*/
/*
var rotateTo = 0.3 + (1 - getMouthOpen);
console.log('rotateTo : ' + rotateTo)

				console.log(Globals.gameController.getGameBoard())
				TweenMax.set(Globals.gameController.getGameBoard().rotation, {
					x: rotateTo,
					ease: Power3.easeInOut
				});*/



				TweenMax.to(Globals.mainScene.getCamera().position, cameraPositionDuration, {
					z: 12 - 7 * getMouthOpen,
					y: 0.6 * getMouthOpen,
					x: 0,
					ease: easeType
				});

				/*var rotateTo = 0.3 + ((1 - getMouthOpen) / 4);
				console.log('rotateTo : ' + rotateTo)

				TweenMax.to(Globals.gameController.getGameBoard().rotation, duration, {
					x: rotateTo,
					y: 0,
					z: 0,
					ease: easeType
				});*/

			/*	TweenMax.to(Globals.gameController.getGameBoard().rotation, cameraPositionDuration, {
					z: 0,
					y: 0,
					x: 0.3 - getMouthOpen * 0.1
				});*/


				if (getMouthOpen >= 1) {
					//
					console.log('RELEASE BALL!');


					//@ts-ignore:
					window['ga']('send', 'event', 'Head Movement', 'In Game - Open Mouth');

					var sound = new Howl({
						src: ['assets/sounds/screaming-nightmare-passing.mp3']
					});

					sound.play();

					Globals.levelScreen.animateOut();


					if (this.allBricksRemoved !== true) {
						TweenMax.to(this.backSideBorder.position, 1, {
							delay: 0.5,
							y: this.backSideBorder.userData.defaultY
						});
					}

					easeType = Power3.easeInOut;
					duration = 2;
					this.status = 'playing';


					TweenMax.to(this.skullHead.rotation, duration, {
						x: 0,
						ease: easeType
					});
					TweenMax.to(this.skullJaw.rotation, duration, {
						x: 0,
						ease: easeType
					});

					var newIntensity = 1.5;
					TweenMax.to(Globals.ball.getLight(), duration, {
						intensity: newIntensity,
						ease: easeType
					});

					TweenMax.to(Globals.mainScene.getCamera().position, duration, {
						z: 12,
						y: 0,
						x: 0
					});

					TweenMax.to(Globals.gameController.getGameBoard().rotation, duration, {
						x: 0.3,
						y: 0,
						z: 0,
						ease: easeType
					});

					this.newGameCallback();
				}

			} else if (this.status === 'playing') {
				TweenMax.to([this.skullObject.rotation, Globals.ball.getBall().rotation], 0.2, {
					y: getFaceX * 2,
					ease: easeType
				});
			}
		}
	}

	public closeMouth = () => {
		TweenMax.set(this.skullHead.rotation, {
			x: 0
		})
		TweenMax.set(this.skullJaw.rotation, {
			x: 0
		})
	};

	public lostLifeRestartWithBall(newGameCallback, backSideBorder, allBricksRemoved) {
		this.allBricksRemoved = allBricksRemoved;
		this.backSideBorder = backSideBorder;
		this.newGameCallback = newGameCallback;
		this.status = 'startGame';
		Globals.faceDetectionLookForLandmarks = true;
	}


	public zoomedIntoSkull = () => {
		//
		this.status = 'animatingOut';

		Globals.faceDetectionTurnedOff = true;
		Globals.faceDetectionLookForLandmarks = false;
		Globals.faceMouthOpenPercentage = 0;
		Globals.faceMouthOpenPercentageTweened.x = 0;
		Globals.main.resetMouthOpenDetect();


		Globals.footer.animateIn();
		Globals.headBangerTop.animateInGameStats();

		TweenMax.to(Globals.ball.getLight(), 6, {
			intensity: 0.0
		});

		//TweenMax.to(this.sceneGroup.position, 0.2, {x: 0, ease: Power1.easeIn})

		this.skullObject.rotation.set(0, 0, 0)


		this.onMouthOpenIntroCallback();

	};


	public getMesh() {
		return this.sceneGroup;
	}

	public resetSkeletonScale() {
		this.skullObject.scale.set(this.defaultSkullScale, this.defaultSkullScale, this.defaultSkullScale);
		this.skullObject.position.set(0, 0, 0);
	}

	public getFireball = () => {
		return this.ball;
	};


	public getSkullRotation() {
		return this.skullObject.rotation;
	}

	public showSkull(callback) {
		this._allowAnimation = true;
		Globals.ball.getBall().visible = true;
		Globals.faceDetectionTurnedOff = false;
		this.mouthOpenCallback = callback;
	}
}
