import {AbstractView} from '../core/AbstractView';
import {MouseEvent} from '../../../lib/com/hellomonday/events/MouseEvent';
import {Linear, TweenMax, Power1} from "gsap/TweenMax";
import {Globals} from "../utils/Globals";
import {Rocks} from "../objects/Rocks";
import {GameLevels} from "../levels/GameLevels";


export class InstructionText {

	private _element;
	private _isShown: boolean = false;
	private _textContent;

	private _nextText = '';

	constructor(element) {
		this._element = element;
		this.init();
	}

	private init() {
		this._textContent = this._element.querySelector('.textContent');
		TweenMax.set(this._element, {y: 50, opacity: 1});
	}

	public changeText = (newText) => {
		//this._textContent = newText;

		this._nextText = newText;

		if (this._isShown === false) {
			this.animateIn();
		}
		else {
			this.animateOut();
		}
	};

	public animateIn = () => {
		if (this._isShown === false) {
			this._textContent.innerHTML = this._nextText;
			this._nextText = '';
			this._element.style.display = 'block';
			this._isShown = true;
			TweenMax.killTweensOf(this._element);
			TweenMax.to(this._element, 0.4, {y: 0, ease: Power1.easeOut});
		}
	};

	public animateOut = () => {
		if (this._isShown === true) {
			this._isShown = false;
			TweenMax.killTweensOf(this._element);
			TweenMax.to(this._element, 0.4, {y: 50, ease: Power1.easeIn, onComplete: this.hide});
		}
	};

	public hide = () => {

		if (this._nextText === '')
		{
			this._element.style.display = 'none';
		}
		else {
			this.animateIn()
		}
	}
}
