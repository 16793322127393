import {AbstractView} from '../core/AbstractView';
import {MouseEvent} from '../../../lib/com/hellomonday/events/MouseEvent';
import {Linear, TweenMax, Power1} from "gsap/TweenMax";
import {Globals} from "../utils/Globals";
import {Rocks} from "../objects/Rocks";

//@ts-ignore: Using Require to import ES5
let faceapi = require('./../face-api.js');


//@ts-ignore: Using Require to import ES5
require('./../OBJLoader.js');

export class Preloader {

	private _introHTMLElement;
	private _loaderElement;


	private _headerBangerHTMLElement;
	private _subHeaderElement;
	private _beginButton;
	private _turnOnSoundElement;
	private _loaderForeground;
	private _note;

	private _detectionCallback;

	private prepareCallback;
	private onCompleteCallback;

	private objectLoadCount: number = 0;
	private textureLoadCount: number = 0;

	private objectLoadCompleted: Boolean = false;
	private textureLoadCompleted: Boolean = false;

	private modelLoadCount: number = 0;
	private modelsToLoad: number = 2;
	private modelLoadCompleted: boolean = false;

	constructor(introHTMLElement, callbackPrepare, detectionCallback) {
		this._introHTMLElement = introHTMLElement;
		this.prepareCallback = callbackPrepare;
		this._detectionCallback = detectionCallback;

	//	this.onCompleteCallback = callbackWhenDone;
		this.init();
	}

	private init() {

		this.animateInPreloaderScreen();
		this.startLoadingAssets();
	}

	private animateInPreloaderScreen() {
		this._headerBangerHTMLElement = this._introHTMLElement.querySelector('.headBangerLogo');
		this._subHeaderElement = this._introHTMLElement.querySelector('.subheader');
		this._beginButton = this._introHTMLElement.querySelector('.begin');
		this._note = this._introHTMLElement.querySelector('.note');
		this._turnOnSoundElement = this._introHTMLElement.querySelector('.rememberSound');
		this._loaderForeground = this._introHTMLElement.querySelector('.loaderForeground');

		this._loaderElement = this._introHTMLElement.querySelector('.loader');


		TweenMax.set(this._headerBangerHTMLElement, {y: -0});
		TweenMax.set(this._subHeaderElement, {y: -0});
		TweenMax.set(this._beginButton, {y: -0});
		TweenMax.set(this._turnOnSoundElement, {y: 100});

		var easeType = Power1.easeOut;
		TweenMax.to(this._headerBangerHTMLElement, 3, {y: 0, opacity: 1, ease: easeType});
		TweenMax.to(this._subHeaderElement, 3, {y: 0, opacity: 1, ease: easeType});
		TweenMax.to(this._note, 3, {y: 0, opacity: 1, ease: easeType});
		TweenMax.to(this._loaderElement, 1, {y: 0, opacity: 1, delay: 0, ease: easeType});
		TweenMax.to(this._turnOnSoundElement, 1.5, {y: 0, opacity: 0.6, delay: 1.5, ease: easeType});

	}

	private clickBegin = (event) => {
		this._beginButton.removeEventListener('click', this.clickBegin);

		document.querySelector('#_webcam').play();

		var easeType = Power1.easeIn;

		TweenMax.killTweensOf(this._headerBangerHTMLElement);
		TweenMax.killTweensOf(this._subHeaderElement);
		TweenMax.killTweensOf(this._beginButton);
		TweenMax.killTweensOf(this._turnOnSoundElement);

		TweenMax.to(this._headerBangerHTMLElement, 0.5, {y: 0, opacity: 0, ease: easeType});
		TweenMax.to(this._subHeaderElement, 0.5, {y: 0, opacity: 0, ease: easeType});
		TweenMax.to(this._beginButton, 0.5, {y: 0, opacity: 0, ease: easeType});
		TweenMax.to(this._note, 0.5, {y: 0, opacity: 0, ease: easeType});
		TweenMax.to(this._turnOnSoundElement, 0.5, {y: 100, opacity: 0, delay: 0, ease: easeType, onComplete: this.everythingIsAnimatedOut});
		Globals.mainScene.startAnimating();
	};

	private everythingIsAnimatedOut = () => {
		TweenMax.to(this._introHTMLElement, 0.5, {opacity: 0, onComplete: this.kill});
	};

	private kill = () => {
		this._introHTMLElement.parentNode.removeChild(this._introHTMLElement);
		//this.onCompleteCallback();
	};

	private startLoadingAssets() {
		this.loadObjects();
		this.loadTextures();
		this.loadModels();
	};

	private loadModels = () => {
		var get = faceapi.nets.tinyFaceDetector.loadFromUri('/models').then(this.modelsLoaded);
		//var get = faceapi.nets.faceExpressionNet.loadFromUri('/models').then(this.modelsLoaded);
		var get = faceapi.nets.faceLandmark68TinyNet.loadFromUri('/models').then(this.modelsLoaded);
		//var get = faceapi.nets.ageGenderNet.loadFromUri('/models').then(this.modelsLoaded);

	};

	private modelsLoaded = () => {
		this.modelLoadCount++;
		if (this.modelLoadCount === this.modelsToLoad) {
			this.modelLoadCompleted = true;
			this.checkObjectAndTextureLoad();
			TweenMax.delayedCall(1, this._detectionCallback);

		}
	};

	private loadObjects() {
		//	for (var i = 0; i < Globals.loadObjectsArray.length; i++) {
		// instantiate a loader
		var loader = new THREE.OBJLoader();

		var loadObject = Globals.loadObjectsArray[this.objectLoadCount];

		// load a resource
		loader.load(
			// resource URL
			loadObject.path,
			// called when resource is loaded
			this.objectLoaded,
			// called when loading is in progresses
			function (xhr) {
			//	console.log((xhr.loaded / xhr.total * 100) + '% loaded');
			},
			// called when loading has errors
			function (error) {
			//	console.log('An error happened');
				console.warn(error)
			}
		);
	}

	private objectLoaded = (object: THREE.Group) => {
		Globals.loadObjectsArray[this.objectLoadCount].object = object;
		this.objectLoadCount++;

		if (this.objectLoadCount === Globals.loadObjectsArray.length) {
			this.objectLoadCompleted = true;
		} else {
			this.loadObjects();
		}

		this.checkObjectAndTextureLoad();

	};

	private loadTextures() {
		//	for (var i = 0; i < Globals.loadObjectsArray.length; i++) {
		// instantiate a loader
		var loader = new THREE.TextureLoader();

		var loadTextures = Globals.loadTexturesArray[this.textureLoadCount];

		// load a resource
		loader.load(
			// resource URL
			loadTextures.path,
			// called when resource is loaded
			this.texturesLoaded,
			// called when loading is in progresses
			function (xhr) {
			//	console.log((xhr.loaded / xhr.total * 100) + '% loaded');
			},
			// called when loading has errors
			function (error) {
			//	console.log('An error happened');
				console.warn(error)
			}
		);
	}

	private texturesLoaded = (texture) => {
		Globals.loadTexturesArray[this.textureLoadCount].texture = texture;
		this.textureLoadCount++;
		if (this.textureLoadCount === Globals.loadTexturesArray.length) {
			this.textureLoadCompleted = true;

		} else {
			this.loadTextures();
		}

		this.checkObjectAndTextureLoad();

	};



	private checkObjectAndTextureLoad() {

		var getAssetLength = Globals.loadTexturesArray.length + Globals.loadObjectsArray.length + this.modelsToLoad;
		var numberOfAssetsLoaded = this.textureLoadCount + this.objectLoadCount + this.modelLoadCount;
		var percentageLoaded = Math.round((numberOfAssetsLoaded / getAssetLength) * 100);

	//	console.log('percentageLoaded : ' + percentageLoaded);

		TweenMax.to(this._loaderForeground, 0.2, {width: percentageLoaded + '%', ease: Linear.easeNone});

		if (this.objectLoadCompleted === true && this.textureLoadCompleted === true && this.modelLoadCompleted === true) {
			this.setupObjects();
		}
	}

	private setupObjects() {
		this.allDone();
	}

	private allDone = () => {
		this.prepareCallback();

		this._beginButton.style.pointerEvents = 'all';
		TweenMax.to(this._beginButton, 3, {y: 0, opacity: 1, ease: Power1.easeOut});

		TweenMax.to(this._loaderElement, 0.6, {y: 0, opacity: 0, scaleX: 0, ease: Power1.easeIn});


		this._beginButton.addEventListener('click', this.clickBegin);

		if (Globals.debugSkipIntro)
		{
			this.clickBegin();
		}
	}
}
