

export class GameLevels {

	// Poision Ivy
	public static poisonIvy:String = '0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 1 1 1 0 0 0 0 0 0 0 0 1 0 1 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 1 0 1 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 1 0 1 0 0 0 0 0 0 0 0 1 1 1 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 1 0 0 1 0 0 1 0 0 0 1 2 1 0 1 0 1 2 1 0 0 0 1 2 1 1 1 2 1 0 0 0 1 0 1 2 1 2 1 0 1 0 0 0 1 0 1 1 1 0 1 0 0 0 0 0 1 0 1 0 1 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0';

	// Ring of Fire
	public static ringOfFire:String = '0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 2 0 0 0 0 0 0 0 0 0 1 2 1 0 0 0 0 0 0 0 1 1 1 1 1 0 0 0 0 0 1 1 1 2 1 1 1 0 0 0 1 1 1 1 1 1 1 1 1 0 0 1 1 1 1 2 1 1 1 1 0 1 0 1 1 0 1 0 1 1 0 1 0 1 1 0 1 0 1 0 1 1 0 1 0 0 1 0 0 0 1 0 0 1 0 1 1 0 0 0 0 0 1 1 0 1 0 1 0 0 0 0 0 1 0 1 0 1 0 0 0 2 0 0 0 1 0 1 0 0 2 2 2 2 2 0 0 1 0 1 1 0 0 0 0 0 1 1 1 0 1 0 1 0 0 0 1 0 1 0 0 0 1 0 1 0 1 0 1 0 0 0 0 0 1 0 1 0 1 0 0 0 0 0 0 1 1 1 1 1 0 0 0 0 0 0 0 0 1 0 0 0 0 0';
	// Nuclear Power
	public static nuclearPower:String = '0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0 0 0 1 0 0 0 0 0 1 1 0 0 0 1 1 0 0 0 1 1 1 0 0 0 1 1 1 0 1 1 1 1 0 0 0 1 1 1 1 1 1 1 1 0 0 0 1 1 1 1 1 1 1 1 0 0 0 1 1 1 1 1 1 1 1 2 0 2 1 1 1 1 0 0 0 2 0 0 0 2 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 1 1 1 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 2 1 2 0 0 0 0 0 0 0 2 1 1 1 2 0 0 0 0 0 0 1 1 1 1 1 0 0 0 0 0 1 1 1 1 1 1 1 0 0 0 1 1 1 1 1 1 1 1 1 0 0 1 1 1 1 1 1 1 1 1 0';

	// Poision Ivy
	public static reverseCross:String = '0 0 0 0 1 1 1 0 0 0 0 0 0 0 0 1 2 1 0 0 0 0 0 0 0 0 1 2 1 0 0 0 0 0 0 0 0 1 2 1 0 0 0 0 0 0 0 0 1 2 1 0 0 0 0 0 0 0 0 1 2 1 0 0 0 0 0 0 0 0 1 2 1 0 0 0 0 0 0 0 0 1 2 1 0 0 0 0 0 1 1 1 1 2 1 1 1 1 0 0 1 1 1 1 2 1 1 1 1 0 0 1 1 1 1 2 1 1 1 1 0 0 1 1 1 1 2 1 1 1 1 0 0 0 0 0 1 2 1 0 0 0 0 0 0 0 0 1 2 1 0 0 0 0 0 0 0 0 1 1 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0';

	// Poision Ivy
	public static lordOfNothing:String = '0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 2 0 0 0 2 0 0 0 2 0 0 1 0 0 0 1 0 0 0 1 0 0 1 1 0 1 1 1 0 1 1 0 0 1 1 0 1 1 1 0 1 1 0 0 1 1 0 1 1 1 0 1 1 0 0 1 1 2 1 2 1 2 1 1 0 0 1 1 1 1 2 1 1 1 1 0 0 1 1 1 1 2 1 1 1 1 0 0 1 1 1 1 1 1 1 1 1 0 0 1 1 1 1 1 1 1 1 1 0 0 1 0 1 0 1 0 1 0 1 0 0 1 1 1 1 1 1 1 1 1 0 0 1 1 1 1 1 1 1 1 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0';
	// Poision Ivy
	public static coffin:String = '0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 1 1 1 0 0 0 0 0 0 0 1 1 0 1 1 0 0 0 0 0 0 1 1 0 1 1 0 0 0 0 0 0 1 0 0 0 1 0 0 0 0 0 0 1 1 0 1 1 0 0 0 0 0 0 1 1 0 1 1 0 0 0 0 0 0 1 1 0 1 1 0 0 0 2 0 0 1 1 0 1 1 0 0 2 0 2 0 1 1 1 1 1 0 2 0 0 0 2 1 1 1 1 1 2 0 0 0 0 0 1 1 2 1 1 0 0 0 0 0 0 1 1 1 1 1 0 0 0 0 0 0 1 1 1 1 1 0 0 0 0 0 0 1 1 1 1 1 0 0 0 0 0 0 1 1 1 1 1 0 0 0 0 0 0 1 1 1 1 1 0 0 0 0 0 0 1 1 1 1 1 0 0 0 0 0 0 1 1 1 1 1 0 0 0 0 0 0 0 1 1 1 0 0 0 0';

	// Poision Ivy
	public static devilHorns:String = '0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0 0 0 1 0 0 0 0 0 0 1 0 0 0 1 0 0 0 0 0 0 1 0 0 0 1 0 0 0 0 0 0 1 0 0 0 1 0 0 0 0 0 0 1 0 0 0 1 0 0 0 0 0 0 1 0 0 0 1 0 0 0 0 0 0 1 1 1 1 1 0 0 0 0 0 0 1 1 1 1 1 0 0 0 0 0 0 1 1 1 1 1 0 0 0 0 0 0 1 1 1 1 1 0 0 0 0 0 0 1 1 1 1 1 0 0 1 0 0 0 1 1 1 1 1 0 1 1 0 0 0 1 1 1 1 1 1 1 1 0 0 0 1 1 1 1 1 1 1 0 0 0 0 1 1 1 1 1 1 1 0 0 0 0 0 1 1 1 1 1 0 0 0 0 0 0 1 1 1 1 0 0 0 0 0 0 0 0 1 1 0 0 0 0 0 0 0 0 0 1 1 0 0 0 0 0 0 0 0 0 1 1 0 0 0 0 0 0 0 0 0 1 1 0 0 0 0 0 0 0 0 0 1 1 0 0 0 0 0 0';
	// Poision Ivy
	public static corpsePaint:String = '0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 1 1 1 0 0 0 0 0 0 0 1 1 1 1 1 0 0 0 0 0 1 1 1 1 1 1 1 0 0 0 1 1 1 1 1 1 1 1 1 0 1 1 2 1 1 1 1 1 2 1 1 1 2 2 2 1 1 1 2 2 2 1 1 1 2 1 1 1 1 1 2 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 0 0 0 1 1 1 1 0 1 1 1 1 1 1 1 1 1 1 0 1 1 1 1 1 1 1 1 1 0 0 0 1 1 1 1 1 1 1 0 0 0 0 1 1 0 1 0 1 1 0 0 0 0 1 0 1 0 1 0 1 0 0 0 0 1 1 1 1 1 1 1 0 0 0 0 1 1 1 1 1 1 1 0 0 0 0 0 1 1 1 1 1 0 0 0 0 0 0 0 1 1 1 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0';
	// Poision Ivy
	public static silentKiller:String = '0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 2 1 2 0 0 0 0 0 0 0 0 1 1 1 0 0 0 0 0 0 0 0 1 1 1 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 1 1 1 0 0 0 1 0 2 0 1 1 1 1 1 0 2 0 0 0 1 1 1 1 1 1 1 0 0 0 0 1 1 1 2 1 1 1 0 0 1 1 1 1 1 0 1 1 1 1 1 0 0 1 1 1 2 1 1 1 0 0 0 0 1 1 1 0 1 1 1 0 0 0 0 1 1 1 2 1 1 1 0 0 1 1 1 1 1 0 1 1 1 1 1 0 0 1 1 1 2 1 1 1 0 0 0 0 1 1 1 1 1 1 1 0 0 0 1 0 1 1 1 1 1 0 1 0 1 0 0 0 1 1 1 0 0 0 1 0 0 0 2 0 1 0 2 0 0 0 0 0 2 0 0 0 0 0 2 0 0 0 1 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0';

	// Poision Ivy
	public static bomb:String = '0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 1 1 1 0 0 0 0 0 0 0 0 1 1 1 0 0 0 0 0 0 0 1 1 1 1 1 0 0 0 0 0 0 1 1 1 1 1 0 0 0 0 0 0 0 1 1 1 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 2 2 2 0 0 0 0 0 0 0 0 2 2 2 0 0 0 0 0 0 0 1 1 1 1 1 0 0 0 0 0 1 1 1 1 1 1 1 0 0 0 0 1 1 1 1 1 1 1 0 0 0 0 1 1 1 1 1 1 1 0 0 0 0 1 1 1 1 1 1 1 0 0 0 0 1 1 1 1 1 1 1 0 0 0 0 1 1 1 1 1 1 1 0 0 0 0 1 1 1 1 1 1 1 0 0 0 0 1 1 1 1 1 1 1 0 0 0 0 1 1 1 1 1 1 1 0 0 0 0 0 1 1 1 1 1 0 0 0 0 0 0 0 1 1 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0';

	// Poision Ivy
	public static sword:String = '0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 1 1 0 0 0 0 0 0 0 0 0 0 1 1 0 0 0 0 0 0 0 0 0 1 1 1 0 0 0 0 0 0 0 0 0 1 1 0 0 0 0 0 0 0 0 0 1 1 1 0 0 0 0 0 2 0 0 0 1 1 1 0 0 0 2 2 0 0 0 1 1 1 1 0 2 2 0 0 0 0 0 1 1 1 2 2 2 0 0 0 0 0 0 1 1 1 2 0 0 0 0 0 0 0 1 1 1 2 0 0 0 0 0 0 0 2 1 1 0 0 0 0 0 0 0 2 1 1 1 0 0 0 0 0 0 2 2 2 1 1 1 0 0 0 0 0 2 2 0 0 1 1 1 0 0 0 2 2 0 0 0 1 1 1 0 0 0 2 0 0 0 0 0 1 1 0 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0';
	// Poision Ivy
	public static blasturbation:String = '0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 1 1 0 0 0 0 0 0 0 0 0 1 1 2 0 0 0 0 0 0 0 0 1 1 1 2 0 0 0 0 0 0 1 1 1 1 1 2 0 0 0 0 1 1 1 1 1 1 1 2 0 0 1 1 1 1 1 1 1 1 1 2 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 2 1 1 1 1 1 1 1 1 1 0 0 2 1 1 1 1 1 1 1 0 0 0 0 2 1 1 1 1 1 0 0 0 0 0 0 2 1 1 1 1 0 0 0 0 0 0 0 2 1 1 0 0 0 0 0 0 0 0 0 1 1 0 0 0 0 0 0 0 0 0 1 1 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0';

	// Poision Ivy
	public static satanicMaster:String = '0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 1 0 0 2 1 0 0 0 0 0 1 2 0 0 1 2 1 0 1 0 1 2 1 0 0 0 1 0 1 0 1 0 1 0 0 0 0 0 1 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 1 0 0 0 0 0 0 0 1 0 0 0 0 0 2 0 2 0 0 0 0 0 1 0 2 2 0 2 2 0 1 0 0 0 0 0 2 0 2 0 0 0 0 0 1 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 1 0 0 0 0 2 2 2 2 2 0 0 0 0 1 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 1 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 1 0 0 0 0 0 0 0 1 0 1 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0';

	public static data = [
		{levelName: 'Enolas Dead', levelLayoutData: GameLevels.reverseCross, levelBallSpeed: 0.7, paddleSize: 1.6},
		{levelName: 'Beneath Eerie Lakes', levelLayoutData: GameLevels.poisonIvy, levelBallSpeed: 0.8, paddleSize: 1.5},
		{levelName: 'A Blackhole Fire', levelLayoutData: GameLevels.nuclearPower, levelBallSpeed: 0.9, paddleSize: 1.4},
		{levelName: 'Perception of Time', levelLayoutData: GameLevels.ringOfFire, levelBallSpeed: 1.1, paddleSize: 1.3},
		{levelName: 'Subdivision', levelLayoutData: GameLevels.lordOfNothing, levelBallSpeed: 1.2, paddleSize: 1.1},
		{levelName: 'Patterns on the Walls', levelLayoutData: GameLevels.silentKiller, levelBallSpeed: 1.3, paddleSize: 1.1},
		{levelName: 'Aligning of the Mind', levelLayoutData: GameLevels.coffin, levelBallSpeed: 1.35, paddleSize: 1},
		{levelName: 'Bloodletting', levelLayoutData: GameLevels.devilHorns, levelBallSpeed: 1.4, paddleSize: 0.9},
		{levelName: 'Vomit of Memories', levelLayoutData: GameLevels.corpsePaint, levelBallSpeed: 1.45, paddleSize: 0.8},
		{levelName: 'Thorazine Goddess', levelLayoutData: GameLevels.sword, levelBallSpeed: 1.5, paddleSize: 0.75},
		{levelName: 'Hexagon Sun', levelLayoutData: GameLevels.blasturbation, levelBallSpeed: 1.55, paddleSize: 0.7},
		{levelName: 'Sacrilege', levelLayoutData: GameLevels.satanicMaster, levelBallSpeed: 1.6, paddleSize: 0.65},
		{levelName: 'The Calling', levelLayoutData: GameLevels.bomb, levelBallSpeed: 1.65, paddleSize: 0.6}
	]
}
