import {AbstractView} from '../core/AbstractView';
import {MouseEvent} from '../../../lib/com/hellomonday/events/MouseEvent';
import {Linear, TweenMax, Power1, Power2, Power3} from "gsap/TweenMax";
import {Globals} from "../utils/Globals";
import {Rocks} from "../objects/Rocks";
import {GameLevels} from "../levels/GameLevels";


export class LevelScreen {

	private _element;
	private _isShown: boolean = false;

	private _levelName;
	private _level;
	private _pentagram;

	private _clickToContinue;

	private _dismissCallback;

	constructor(element) {
		this._element = element;
		this.init();
	}

	private init() {
		this._level = this._element.querySelector('.level');
		this._levelName = this._element.querySelector('.levelName');
		this._pentagram = this._element.querySelector('.pentagramImage');

		this._clickToContinue = this._element.querySelector('.clickToContinue');
		this._clickToContinue.addEventListener('click', this.animateOut);

		TweenMax.set(this._element, {opacity: 0});
	}

	public animateIn = (dismissCallback) => {
		if (this._isShown === false) {

			this._dismissCallback = dismissCallback;

			Globals.headBangerTop.updateLevel();


			// Add text to the level screen
			var getLevelInfo = GameLevels.data[Globals.gameVariables.currentLevel];
			this._level.innerHTML = 'level ' + (Globals.gameVariables.currentLevel + 1);
			this._levelName.innerHTML = String(GameLevels.data[Globals.gameVariables.currentLevel].levelName);
			this._isShown = true;
			this._element.style.display = 'block';
			TweenMax.killTweensOf(this._element);
			TweenMax.to(this._element, 0.7, {opacity: 1, ease: Power3.easeOut});
			TweenMax.set(this._pentagram, {rotation: 0});
			TweenMax.to(this._pentagram, 7, {rotation: 40, ease: Linear.easeNone});

			//TweenMax.delayedCall(5, this.autoAnimateOut);
		}
	};

	private autoAnimateOut = () => {
		if (this._isShown === true) {
			this.animateOut();
		}
	}

	public animateOut = () => {
		if (this._isShown === true) {
			this._isShown = false;
			TweenMax.killTweensOf(this._element);
			TweenMax.killDelayedCallsTo(this.autoAnimateOut);
			TweenMax.to(this._element, 0.3, {opacity: 0, ease: Power3.easeIn, onComplete: this.hide});


		}
	};

	public hide = () => {
		if (this._dismissCallback) {
			this._dismissCallback();
			this._dismissCallback = null;
		}
		this._element.style.display = 'none';
	}
}
