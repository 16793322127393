import {AbstractView} from '../core/AbstractView';
import {MouseEvent} from '../../../lib/com/hellomonday/events/MouseEvent';
import {Linear, TweenMax, Power1, Power2, Power3} from "gsap/TweenMax";
import {Globals} from "../utils/Globals";
import {Rocks} from "../objects/Rocks";
import {GameLevels} from "../levels/GameLevels";
//@ts-ignore: Using Require to import ES5
let Howler = require('./../howler.js');

export class Footer {

	private _element;


	private _isShown: boolean = false;

	private _soundController;
	private _soundOnElement;
	private _soundOffElement;

	private _currentVolume = {volume: 1};

	private _soundTurnedOn = true;

	constructor(element) {
		this._element = element;
		this._soundController = element.querySelector('.soundController');
		this._soundOnElement = element.querySelector('.soundOn');
		this._soundOffElement = element.querySelector('.soundOff');

		this.init();
	}

	private init() {
		TweenMax.set(this._element, {y: 70, opacity: 0});
		this._soundController.addEventListener('click', this.toggleSound);
	}

	public animateIn = () => {
		if (this._isShown === false) {
			this._element.style.display = 'block';
			TweenMax.to(this._element, 2, {y: 0, opacity: 1, ease: Power2.easeOut});
		}
	};

	private toggleSound = (event) => {
		if (this._soundTurnedOn === true) {
			this._soundTurnedOn = false;
			this.soundOff();
		}
		else {
			this._soundTurnedOn = true;
			this.soundOn();
		}
	}

	private soundOn = () => {
		this._soundOnElement.style.display = 'block';
		this._soundOffElement.style.display = 'none';
		TweenMax.to(this._currentVolume, 0.3, {volume: 1, onUpdate: this.volumeOnUpdate});
	};

	private soundOff = () => {
		this._soundOnElement.style.display = 'none';
		this._soundOffElement.style.display = 'block';
		TweenMax.to(this._currentVolume, 0.3, {volume: 0, onUpdate: this.volumeOnUpdate});
	};

	private volumeOnUpdate = () => {
	/*	console.log(Howler);
		console.log(Howl)*/
		Howler.Howler.volume(this._currentVolume.volume);
	}
}
