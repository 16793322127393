//Keep typescript from complaining about touch test.
import {MainScene} from "./MainScene";

declare class DocumentTouch {
}

import {ViewManager} from '../managers/ViewManager';
import {GameController} from "../game/GameController";
import {IntroScene} from "../game/IntroScene";
import {Rain} from "../game/Rain";
import {GameStats} from "../game/GameStats";
import {Rocks} from "../objects/Rocks";
import {Ball} from "../game/Ball";
import {Player} from "../game/Player";
import {Skull} from "../game/Skull";
import {HeadBangerTop} from "./HeadBangerTop";
import {LevelScreen} from "./LevelScreen";
import * as THREE from "three";
import {InstructionText} from "./InstructionText";
import {Footer} from "./Footer";
import {About} from "./About";
import {FloatingIslands} from "../game/FloatingIslands";
import {LevelsCompleted} from "./LevelsCompleted";
import {WebcamNotAllowed} from "./WebcamNotAllowed";

(window as any).THREE = THREE;


export class Globals {
	public static DEBUG: boolean = false;

	public static SITE_WRAPPER: HTMLDivElement = document.getElementById('App') as HTMLDivElement;
	public static IS_TOUCH_DEVICE: boolean = ('ontouchstart' in window) || ((window as any).DocumentTouch && document instanceof DocumentTouch); // https://github.com/Modernizr/Modernizr/blob/master/feature-detects/touchevents.js
	public static IS_IE: boolean = false;

	public static VIEW_MANAGER: ViewManager;

	// DEBUG START
	public static debugUseMouse: boolean = false;
	public static allowKeyboardCheat: boolean = false;
	public static speedUpIntroWithFactor = 1; //1;// 0.01;

	public static debugSkipIntro: boolean = false;

	public static speedUpTextIntroWithFactor: number = 1; // 1;//0.01;
	public static speedUpFloatingIslandWith: number = 1;

	public static skipMoveLeftAndRight: boolean = false;
	public static skipSkeletonInIntro: boolean = false;


	public static measureWebGLAnimateStarts: boolean = true;
	// DEBUG END

	// FACE DETECTIOn
	public static faceDetectionLookForLandmarks: boolean = false;
	public static faceDetectionTurnedOff: boolean = true;

	public static webcamXPosition: number = 0.5;
	public static webcamYPosition: number = 0.5;

	public static faceExpressions: Object = null;
	public static faceMouthOpenPercentage: number = 0;
	public static faceMouthOpenPercentageTweened: any = {x: 0};
	public static faceMouthOpenPercentageSmoothed: number = 0;
	public static faceXPositionSmoothed: number = 0.5;
	public static headbangDetected: boolean = false;


	public static colorAccentLight: number = 0xf36e15; //0x062d89; //0x062d89;

	public static customLevelData = '';

	public static brickMaterial;

	public static main;
	public static stats: any;

	public static mainScene: MainScene;
	public static gameController: GameController;
	public static floatingIslands: FloatingIslands;

	public static introScene: IntroScene;

	public static objectSkull: THREE.Group;

	public static ball: Ball;
	public static player: Player;
	public static skull: Skull;

	public static gameStats: GameStats;

	public static rain: Rain;

	public static objectRocks: Rocks;

	public static startWebCamFunction;
	public static headBangerTop: HeadBangerTop;
	public static levelScreen: LevelScreen;
	public static instructionText: InstructionText;

	public static footer: Footer;
	public static levelsCompleted: LevelsCompleted;
	public static about: About;
	public static webcamNotAllowed:WebcamNotAllowed;

	public static scaleMusicFactor = {factor: 1};


	public static brickMaterial0 = new THREE.MeshStandardMaterial({
		flatShading: true,
		color: 0xff0000,
		metalness: 0.2,
		roughness: 0.5
	});
	public static brickMaterial1 = new THREE.MeshStandardMaterial({
		flatShading: true,
		color: 0x999999,
		metalness: 0.2,
		roughness: 0.5
	});
	public static brickMaterial2 = new THREE.MeshStandardMaterial({
		flatShading: true,
		color: 0x333333,
		metalness: 0.2,
		roughness: 0.5
	});


//trunk

//	var material = new THREE.MeshPhongMaterial({color: brickColor, metalness: 0.8});

	/*
			{path: '/assets/images/skull/skull_Roughness.jpg', name: 'skullRoughness', texture: ''},
		{path: '/assets/images/skull/skull_Metallic.jpg', name: 'skullMetallic', texture: ''},
	 */

	public static loadTexturesArray: Array<any> = [
		{path: '/assets/images/skull/skull_Base_Color_copy.jpg', name: 'skullBase', texture: ''},

		{path: '/assets/images/skull/skull_Normal_DirectX.jpg', name: 'skullNormal', texture: ''},
		{path: '/assets/images/skull/skull_Mixed_AO.jpg', name: 'skullAO', texture: ''},

		{path: '/assets/3d/Rock_10/optimised/Rock_10_e.jpg', name: 'rock10e', texture: ''},
		{path: '/assets/3d/Rock_10/optimised/Rock_10_n.jpg', name: 'rock10n', texture: ''},
		{path: '/assets/3d/Rock_10/optimised/Rock_10_d.jpg', name: 'rock10d', texture: ''},

		{path: '/assets/images/logo_hellmonday_white.png', name: 'logo_hellomonday', texture: ''},
		{path: '/assets/images/logo_dracaris.png', name: 'logo_dracaris', texture: ''},

		{path: '/assets/images/explosion.png', name: 'fire', texture: ''},
		{path: '/assets/images/cloud.jpg', name: 'smoke', texture: ''},

		{path: '/assets/images/skull_left@2x.png', name: 'skull_left', texture: ''},
		{path: '/assets/images/skull_right@2x.png', name: 'skull_right', texture: ''},
		{path: '/assets/images/skull_head@2x.png', name: 'skull_bang', texture: ''},
		{path: '/assets/images/pentagram@2x.png', name: 'pentagram', texture: ''}


	];

	public static getNamedTexture(findName: string) {
		for (var i = 0; i < this.loadTexturesArray.length; i++) {
			var currentObject = this.loadTexturesArray[i];
			var getName = currentObject.name;
			if (getName === findName) {
				return currentObject.texture;
			}
		}
		console.warn('Error: Not texture with the name ' + findName + ' found');
	}


	public static loadObjectsArray: Array<any> = [
		{path: '/assets/3d/skullBig.obj', name: 'skullBig', object: ''},
		{path: '/assets/3d/rock10_fractured.obj', name: 'rocksmall', object: ''}];

	public static getNamedObject(findName: string) {
		for (var i = 0; i < this.loadObjectsArray.length; i++) {
			var currentObject = this.loadObjectsArray[i];
			var getName = currentObject.name;
			if (getName === findName) {
				return currentObject.object;
			}
		}
		console.warn('Error: Not object with the name ' + findName + ' found');
	}

	public static gameVariables = {
		gameMapWidth: 2.5,
		gameMapHeight: 3.95,
		brickWidth: 2.5 / 11,
		brickHeight: 2.5 / 20,
		brickMargin: 0.03,
		currentLevel: 0,
		paddleMoveDistance: 6.3
	};


	public static brickWidthWithPadding: number = Globals.gameVariables.brickWidth - Globals.gameVariables.brickMargin;
	public static brickHeightWithPadding: number = Globals.gameVariables.brickHeight - Globals.gameVariables.brickMargin;


	public static brickGeometry = new THREE.BoxBufferGeometry(Globals.brickWidthWithPadding, 0.05, Globals.brickHeightWithPadding);


	public static smooth(values, alpha) {
		var weighted = Globals.average(values) * alpha;
		var smoothed = [];
		for (var i in values) {
			var curr = values[i];
			var prev = smoothed[i - 1] || values[values.length - 1];
			var next = curr || values[0];
			var improved = Number(Globals.average([weighted, prev, curr, next]).toFixed(2));
			smoothed.push(improved);
		}
		return smoothed;
	}

	public static average(data) {
		var sum = data.reduce(function (sum, value) {
			return sum + value;
		}, 0);
		var avg = sum / data.length;
		return avg;
	}

	public static Normalize(array, value) {
		for (var i = 0, len = array.length; i < len; i++) {
			if (parseInt(array[i]) > value) array[i] = value;
		}
	}
}
