import {AbstractView} from '../core/AbstractView';
import {MouseEvent} from '../../../lib/com/hellomonday/events/MouseEvent';
import {Linear, TweenMax, Power1} from "gsap/TweenMax";
import {Globals} from "../utils/Globals";
import {Rocks} from "../objects/Rocks";


export class HeadBangerTop {

	private _element;
	private _gameStatsLeftElements;
	private _gameStatsRightElements;
	private _aboutButton;
	private _aboutButtonText;

	private _currentScore: number = 0;
	private _newScore = {score: 0};

	private _scoreStat;
	private _roundStat;
	private _livesStat;



	constructor(element) {
		this._element = element;
		this._gameStatsLeftElements = element.querySelector('.gameStatsLeft');
		this._gameStatsRightElements = element.querySelector('.gameStatsRight');
		this._aboutButton = element.querySelector('.aboutButton');
		this._aboutButtonText = this._aboutButton.querySelector('.text');

		this._aboutButton.addEventListener('click', this.openAbout);

		this._scoreStat = element.querySelector('.scoreCount');
		this._roundStat = element.querySelector('.roundCount');
		this._livesStat = element.querySelector('.livesCount');

		this.init();
	}

	private openAbout = () => {
		var willOpen = Globals.about.toggle();
		if (willOpen === true) {
			this._aboutButtonText.innerHTML = 'CLOSE';
			TweenMax.to(this._gameStatsRightElements, 0.3, {y: -150, ease: Power1.easeIn});

		} else {

			this._aboutButtonText.innerHTML = 'ABOUT';
			TweenMax.to(this._gameStatsRightElements, 0.3, {y: 0, ease: Power1.easeOut});

		}
	};

	private init() {
		TweenMax.set(this._element, {y: -80});
		TweenMax.set(this._gameStatsLeftElements, {y: -80});
		TweenMax.set(this._gameStatsRightElements, {y: -150});
	}

	public animateIn = () => {
		this._element.style.visibility = 'visible';
		TweenMax.to(this._element, 1, {y: 0, opacity: 1, ease: Power1.easeOut});
	};

	public animateInGameStats = () => {
		TweenMax.to(this._gameStatsLeftElements, 1, {y: 0, opacity: 1, ease: Power1.easeOut});
		TweenMax.to(this._gameStatsRightElements, 1, {y: 0, opacity: 1, ease: Power1.easeOut});
	};

	public updateScore(addToScore) {
		this._currentScore += addToScore;
		TweenMax.to(this._newScore, 0.2, {
			score: this._currentScore,
			rounded: true,
			onUpdate: this.setScore,
			ease: Power1.easeOut
		});
	}

	public updateLevel() {
		this._roundStat.innerHTML = (Globals.gameVariables.currentLevel + 1) + ' / ' + 13;
	}

	private setScore = () => {
		//var useScore = Math.round(this._newScore.score);
		this._scoreStat.innerHTML = Math.round(this._newScore.score);//this.pad(useScore, 15, '');//
	};

	private pad = (n, width, z) => {
		z = z || '0';
		n = n + '';
		return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
	};

	/*public updateLevel() {
		this._roundStat.innerHeight = Globals.gameVariables.currentLevel + 1;
	};*/

	public getScore()
	{
		return this._currentScore;
	}

}
