import * as THREE from 'three';
import {Mask} from "./Mask";
import {Globals} from "./Globals";
import {Linear, Power1, Quad, TweenMax, Circ} from "gsap/TweenMax";
import {Ball} from "../game/Ball";
import {Player} from "../game/Player";
import {Brick} from "../game/Brick";
import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";
import {GameController} from "../game/GameController";
import {FloatingIslands} from "../game/FloatingIslands";
import {Skull} from "../game/Skull";
import {Rain} from "../game/Rain";
import {IntroFlowModule} from "../modules/IntroFlowModule";
import {MusicVisualizer} from "./MusicVisualizer";

(window as any).THREE = THREE;


//@ts-ignore: Using Require to import ES5
let Zlib = require('./../inflate.min.js');
(window as any).Zlib = Zlib.Zlib;

//@ts-ignore: Using Require to import ES5
require('./../OBJLoader.js');

//@ts-ignore: Using Require to import ES5
require('./../FBXLoader.js');

//@ts-ignore: Using Require to import ES5
require('./../TGALoader.js');
(window as any).THREE.TGALoader = THREE.TGALoader;

/*
//@ts-ignore: Using Require to import ES5
require('./SubdivisionModifier.js');
//@ts-ignore: Using Require to import ES5
require('./TessellateModifier.js');
//@ts-ignore: Using Require to import ES5
require('./OBJLoader.js');
//@ts-ignore: Using Require to import ES5
require('./MTLLoader.js');*/

export class MainScene {

	private camera: THREE.PerspectiveCamera;
	private scene: THREE.Scene;
	private renderer: THREE.WebGLRenderer;
	private start: number = Date.now();

	private pointLight: THREE.PointLight;
	private ambientLight: THREE.AmbientLight;

	private sceneGroup: THREE.Group = new THREE.Group();

	private floatingIslands: FloatingIslands;
	private skull: Skull;
	private rain: Rain;
	private ball: Ball;
	private dragonMesh: THREE.Mesh;

	private mixer;

	private clock = new THREE.Clock();


	private introFlow: IntroFlowModule;

	constructor() {
		this.init();
		this.setupWorlds();
		///	this.switchToSelectionScreen();
		this.animate();
	}

	private init() {
		this.camera = new THREE.PerspectiveCamera(25, window.innerWidth / window.innerHeight, 1, 1500);
		this.scene = new THREE.Scene();
		this.scene.background = new THREE.Color( 0x000000 );

		const color = 0x121212;
		const density = 0.005;
		//this.scene.fog = new THREE.Fog(color, 500, 1200);

		this.renderer = new THREE.WebGLRenderer({antialias: true, alpha: false});
		this.renderer.setSize(window.innerWidth, window.innerHeight);
		this.renderer.setClearColor(0x000000);
		this.renderer.domElement.style.zIndex = '5';
		this.renderer.domElement.style.position = 'absolute';
		this.renderer.domElement.style.top = '0px';
		this.renderer.domElement.style.backgroundColor = '#000000';
		this.renderer.debug.checkShaderErrors = false;

		window.scene = this.scene;
		window.THREE = THREE;

		/*
		this.renderer.shadowMap.enabled = true;
		this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
		*/

		// Force much higher pixelratio to check performance
		this.renderer.setPixelRatio(1);

		document.body.appendChild(this.renderer.domElement);

		window.addEventListener('resize', this.onWindowResize, false);
		this.onWindowResize();

		this.ambientLight = new THREE.AmbientLight(0xffffff);
		this.ambientLight.intensity = 0.5;
		this.scene.add(this.ambientLight);

		this.camera.position.set(0, 0, 12);
		this.camera.lookAt(new THREE.Vector3(0, 0, 0));

		this.pointLight = new THREE.PointLight(0xffffff, 4, 100);
		this.pointLight.position.set(0, 0, 14);
		//this.pointLight.castShadow = true;
		this.pointLight.lookAt(this.camera.position);
		this.scene.add(this.pointLight);

		this.scene.add(this.sceneGroup)
	};

	private setupWorlds() {
		//	this.sceneGroup.add(this.floatingIslands.getMesh());

		this.ball = new Ball();
		Globals.ball = this.ball;

		//var musicVisualizer = new MusicVisualizer(this.scene);

		this.skull = new Skull(this.gotoIsland);
		Globals.skull = this.skull;
		//this.skull.getMesh().visible = false;
		this.floatingIslands = new FloatingIslands(this.sceneGroup);
		this.floatingIslands.getMesh().add(this.skull.getMesh());
		this.skull.getMesh().position.z = 0;

		Globals.floatingIslands = this.floatingIslands;

		this.rain = new Rain(this.scene);
		Globals.rain = this.rain;
		//var rainMesh = this.rain.getMesh();

		Globals.gameController = new GameController(this.scene);


		this.floatingIslands.getMesh().add(this.rain.getMesh());
		//	rainMesh.rotation.x = THREE.Math.degToRad(-90);

		this.sceneGroup.position.z = 1000;


		//console.log('start loading')
		// model
		/*	var loader = new THREE.FBXLoader();
            var mixer;
            var sceneOne = this.sceneGroup;
            var test = this.mixer;
            loader.load('assets/3d/fly.fbx', this.dragonLoaded, function (xhr) {
                    console.log((xhr.loaded / xhr.total * 100) + '% loaded FBX');
                },
                // called when loading has errors
                function (error) {
                    console.log('An error happened');
                    console.warn(error)
                });

    */
		this.introFlow = new IntroFlowModule(this.sceneGroup);

	}

	public startAnimating() {
		this.introFlow.start();
	}

	private dragonLoaded = (object) => {
		console.log('DONE')
		this.mixer = new THREE.AnimationMixer(object);

		console.log(this.mixer)

		console.log(object.animations)
		object.rotation.y = THREE.Math.degToRad(70);
		object.rotation.x = THREE.Math.degToRad(45);

		/*object.traverse(function (child) {

			var map = Globals.getNamedTexture('skullBase');
			var roughnessMap = Globals.getNamedTexture('skullRoughness');
			var metalMap = Globals.getNamedTexture('skullMetallic');

			var normal = Globals.getNamedTexture('skullNormal');
			var aoMap = Globals.getNamedTexture('skullAO');

			//map.repeat.set(4, 4);
			var material = new THREE.MeshPhongMaterial({
				flatShading: false,
				color: 0xcccccc,
				roughnessMap: roughnessMap,
				roughness: 0.3,
				map: map,
				normalMap: normal,
				metalness: 1,
				metalnessMap: metalMap,
				aoMap: aoMap,
				bumpMap: roughnessMap,
				skinning: true
			});


			if (child.isMesh) {
				child.material = material;
				child.material.needsUpdate = true;// = THREE.SmoothShading;
				//child.castShadow = true;
				//child.receiveShadow = true;
			}

		});*/


		object.scale.set(0.005, 0.005, 0.005);


		//	TweenMax.to(object.position, 10, {x:3, y: 0, z: -300});

		var action = this.mixer.clipAction(object.animations[0]);
		//console.log(action)
		action.play();

		this.dragonMesh = object;

		// See the dragon from the top
		this.dragonMesh.rotation.x = THREE.Math.degToRad((20));
		this.dragonMesh.rotation.y = THREE.Math.degToRad(90);
		this.dragonMesh.rotation.z = THREE.Math.degToRad(70);

		this.dragonMesh.position.z = -30;
		this.dragonMesh.position.x = -20;
		this.dragonMesh.position.y = 0;

		this.scene.add(object);
	};


	public gotoIsland = (placeBallBack) => {



	//	console.log('gotoIsland : ' + Globals.gameVariables.currentLevel)
		this.showIsland(Globals.gameVariables.currentLevel, placeBallBack);
	}



	private showIsland = (islandNumber, placeBallBack) => {
		TweenMax.to(this.pointLight, 1, {intensity: 1});
		this.floatingIslands.flyToObject(islandNumber, this.skull.getFireball(), placeBallBack);
	};

	public startGame = () => {

		//this.rain.stopFlashing();
//alert('startGame')
		TweenMax.to(Globals.ball.getLight(), 3, {
			intensity: 0.5
		});

			Globals.gameController.animateGameboardIn();

		// Fly Dragon to the game.. : )
		//TweenMax.to(this.dragonMesh.position, 20, {x: -0.5, y: 0, z: 8});
	};


	private onWindowResize = () => {
		this.camera.aspect = window.innerWidth / window.innerHeight;
		this.camera.updateProjectionMatrix();
		this.renderer.setSize(window.innerWidth, window.innerHeight);
	};

	private animate = () => {
		//setTimeout(this.request, 1000 / 45 );
		//TweenMax.delayedCall(1 / 60, this.animate);

		if (Globals.measureWebGLAnimateStarts) {
			Globals.stats.begin();
		}

		var time = performance.now() * 0.001;
		var delta = this.clock.getDelta();


		if (this.mixer) {
			this.mixer.update(delta);
		}

		if (Globals.gameController) {
			Globals.gameController.animate();
			//Globals.gameController.updatePlayer(Globals.faceXPositionSmoothed);

		}
		if (this.skull) {
		//	this.skull.animate(this.start);
		}
		if (this.rain) {
			this.rain.animate(this.sceneGroup);
		}

		if (this.introFlow) {
			this.introFlow.animate();
		}


		if (Globals.ball) {
			Globals.ball.updateShader(this.start);
		}

		this.renderer.render(this.scene, this.camera);


		if (Globals.measureWebGLAnimateStarts) {
			Globals.stats.end();
		}

		//	this.camera.position.x = Globals.webcamXPosition * 10;
		//	this.camera.position.y = Globals.webcamYPosition * 10;
		//this.camera.rotation.y = -(Globals.webcamXPosition / 4);
		//this.camera.rotation.x = -(Globals.webcamYPosition / 4);

		requestAnimationFrame(this.animate);
	};




	public calcDistance(p0, p1) {
		return Math.sqrt(
			(p1.x - p0.x) * (p1.x - p0.x) +
			(p1.y - p0.y) * (p1.y - p0.y));
	}

	public getScene = () => {
		return this.scene;
	};

	public getSceneGroup = () => {
		return this.sceneGroup;
	};

	public getCamera = () => {
		return this.camera;
	};

	public getAmbientLight = () => {
		return this.ambientLight;
	};

	public getDragonMesh = () => {
		return this.dragonMesh;
	};

	public getPointLight = () => {
		return this.pointLight;
	};
}
