import {WindowManager} from './utils/WindowManager';
import {ModuleFactory} from '../lib/com/hellomonday/core/ModuleFactory';
import {Globals} from './utils/Globals';
import {ViewManager} from './managers/ViewManager';
import {TestModule} from './modules/TestModule';
import {Footer} from './utils/Footer';
import {About} from './utils/About';
import {LevelsCompleted} from './utils/LevelsCompleted';

import {TestView} from './views/TestView/TestView';
import {MainView} from './views/MainView/MainView';
import {MainScene} from "./utils/MainScene";
import {ChooseDifficulty} from "./views/ChooseDifficulty/ChooseDifficulty";
import {TweenMax, Linear, Power2, Power1, Power3} from "gsap/TweenMax";
import {GameView} from "./views/GameView/GameView";
import {Preloader} from "./utils/Preloader";
import {HeadBangerTop} from "./utils/HeadBangerTop";
import {LevelScreen} from "./utils/LevelScreen";
import {MusicVisualizer} from "./utils/MusicVisualizer";
import {InstructionText} from "./utils/InstructionText";
import {WebcamNotAllowed} from "./utils/WebcamNotAllowed";


//@ts-ignore: Using Require to import ES5
let faceapi = require('./face-api.js');
//@ts-ignore: Using Require to import ES5
let Stats = require('./stats.js');
//@ts-ignore: Using Require to import ES5
let Howler = require('./howler.js');

//@ts-ignore: Using Require to import ES5
require('./OBJLoader.js');


const modules = {
	TestModule
};

class Main {

	private _webcam: HTMLVideoElement = <HTMLVideoElement>document.querySelector('#_webcam');

	private videoWidth: number = 640 / 1;
	private videoHeight: number = 480 / 1;

	private mouseDownCount: object = {count: 0};

	private tinyDetectorOptions: any = new faceapi.TinyFaceDetectorOptions({inputSize: 32 * 6, scoreThreshold: 0.1});

	private preloader: Preloader;

	private faceMouthOpenArray = [];
	private xFacePositionArray = [];
	private headBangerPositionYArray = [];
	private _webcamCallback;


	constructor() {
		Globals.main = this;

		//WindowManager.getInstance();
		//Globals.VIEW_MANAGER = new ViewManager(document.body.querySelector('#ViewContainer'));


		var isMobile = false; //initiate as false
// device detection
		if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
			|| /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
			isMobile = true;
		}

		if (isMobile != true) {
			this.init();
		} else {
			document.querySelector('#MobileNotice').style.display = 'block';
		}

		//	new MusicVisualizer();

	}

	private init() {

		// Remove all console logs
		window.console.log = function() {};



		var url = new URL(window.location.href);
		var useLevel = url.searchParams.get('useLevel');
		var paddleMoveDistance = url.searchParams.get('paddleMoveDistance');
		var skipIntro = url.searchParams.get('skipIntro');
		var showStats = url.searchParams.get('showStats');


		if (useLevel) {
			Globals.gameVariables.currentLevel = Number(useLevel);
		}
		if (paddleMoveDistance) {
			Globals.gameVariables.paddleMoveDistance = Number(paddleMoveDistance);
		}
		if (skipIntro) {
			Globals.speedUpFloatingIslandWith = 0.01;
			Globals.speedUpIntroWithFactor = 0.01; //1;// 0.01;
			Globals.debugSkipIntro = true;
			Globals.speedUpTextIntroWithFactor = 0.01; // 1;//0.01;
			Globals.skipMoveLeftAndRight = true;
			Globals.skipSkeletonInIntro = true;
			//Globals.speedUpFloatingIslandWith = 0.01; // 1;//0.01;
		}


		Globals.stats = new Stats();
		Globals.stats.showPanel(0);
		if (showStats) {
			document.body.appendChild(Globals.stats.dom);
		}

		Howl.pool = 10;

		this.preloader = new Preloader(document.querySelector('#Preloader'), this.assetsLoaded, this.detectionCallOne);

		Globals.headBangerTop = new HeadBangerTop(document.querySelector('#HeadBangerTop'));
		Globals.levelScreen = new LevelScreen(document.querySelector('#LevelScreen'));
		Globals.instructionText = new InstructionText(document.querySelector('#InstructionText'));
		Globals.footer = new Footer(document.querySelector('#Footer'));
		Globals.about = new About(document.querySelector('#About'));
		Globals.levelsCompleted = new LevelsCompleted(document.querySelector('#LevelsCompleted'));
		Globals.webcamNotAllowed = new WebcamNotAllowed(document.querySelector('#WebcamNotAllowed'));


	}


	private assetsLoaded = () => {


		Globals.mainScene = new MainScene();

		/*Globals.VIEW_MANAGER.registerView({name: 'TestView', view: TestView});
		Globals.VIEW_MANAGER.registerView({name: 'MainView', view: MainView});
		Globals.VIEW_MANAGER.registerView({name: 'ChooseDifficulty', view: ChooseDifficulty});
		Globals.VIEW_MANAGER.registerView({name: 'GameView', view: GameView});

		Globals.VIEW_MANAGER.init();*/

		//WindowManager.signalResize.add(this.resize);

		this.resize();
		//console.log(faceapi.nets)


		//TweenMax.set(this._webcam, {scaleX: -1});


		if (Globals.debugUseMouse != true) {

		} else {
			//	Globals.mainScene.animateIn();
			window.addEventListener('mousemove', this.onMouseMove);
			window.addEventListener('mousedown', this.onMouseDown);
			window.addEventListener('mouseup', this.onMouseUp);
		}
		Globals.startWebCamFunction = this.startWebcam;
	};


	/**
	 * Debug events for Mouse
	 * @param event
	 */
	private onMouseMove = (event: MouseEvent) => {
		var getPercentageX = event.pageX / window.innerWidth;
		Globals.webcamXPosition = getPercentageX;

		var getPercentageY = event.pageY / window.innerHeight;
		Globals.webcamYPosition = getPercentageY;

		Globals.faceXPositionSmoothed = Globals.webcamXPosition;

		if (Globals.gameController) {
			Globals.gameController.updatePlayer(getPercentageX);
		}
		if (Globals.skull) {
			Globals.skull.animate(0);
		}
	};

	private onMouseDown = (event: MouseEvent) => {
		TweenMax.to(this.mouseDownCount, 0.5, {count: 1, onUpdate: this.mouseDownUpdate});


	};

	private mouseDownUpdate = () => {
		Globals.faceMouthOpenPercentage = this.mouseDownCount.count;
		Globals.faceMouthOpenPercentageTweened.x = Globals.faceMouthOpenPercentage;
		Globals.faceMouthOpenPercentageSmoothed = Globals.faceMouthOpenPercentage;


		if (Globals.skull) {
			Globals.skull.animate(0);
		}
	};


	private onMouseUp = (event: MouseEvent) => {
		TweenMax.to(this.mouseDownCount, 0.3, {count: 0, onUpdate: this.mouseDownUpdate});


	};

	private startWebcam = (webcamCallback) => {

		this._webcamCallback = webcamCallback;
		console.log('start webcam');

		var constraints = {
			video: {
				facingMode: 'user',
				width: this.videoWidth,
				height: this.videoHeight,
				frameRate: 30
			},
			audio: false
		};

		navigator.mediaDevices.getUserMedia(constraints).then(this.userMediaReady).catch(this.webcamNotReady);
	};


	private userMediaReady = (stream) => {
		this._webcam.src = '';
		this._webcam.srcObject = stream;
		this._webcam.addEventListener('canplay', this.onWebcamPlaying);

		window['ga']('send', 'event', 'Webcam', 'Allowed');

		this._webcam.play();

		TweenMax.delayedCall(1.25, this._webcamCallback);
	};

	private onWebcamPlaying = () => {
		/*var canvasFromVideo = faceapi.createCanvasFromMedia(this._webcam);

		// try to create a WebGL canvas (will fail if WebGL isn't supported)

		console.log(fx)
		try {
			var canvas = fx.canvas();
		} catch (e) {
			alert(e);
			return;
		}

		var texture = canvas.texture(this._webcam);
			console.log(texture)*/

		this.detection();
	};


	private webcamNotReady(error) {
		console.dir(error);
		if (error && error.name) {
			if ((error.name == 'NotAllowedError') || (error.name == 'PermissionDismissedError') || (error.name == 'NotFoundError')) {

				Globals.webcamNotAllowed.animateIn();

				window['ga']('send', 'event', 'Webcam', 'Not allowed - or not working');
			}
		}
	}

	private detection = () => {


		var useTiny = this.tinyDetectorOptions;
		var resultFunction = this.faceResult;
		var _webcam = this._webcam;
		var _detection = this.detection;

		async function callDetection() {
			if (Globals.debugUseMouse === false) {
				if (Globals.faceDetectionTurnedOff === false) {
					if (Globals.measureWebGLAnimateStarts === false) {
						Globals.stats.begin();
					}

					if (Globals.faceDetectionLookForLandmarks === true) {
						//withAgeAndGender().withFaceExpressions()
						const detection = await faceapi.detectSingleFace(_webcam, useTiny).withFaceLandmarks(true)
						resultFunction(detection)
					} else {
						const detection = await faceapi.detectSingleFace(_webcam, useTiny)
						resultFunction(detection)
					}
				} else {
					TweenMax.delayedCall(0.1, _detection);
				}
			}
		}

		callDetection();
	};

	private detectionCallOne = () => {
		faceapi.detectSingleFace(this._webcam, this.tinyDetectorOptions).then(this.detectionCallOneResult).catch(this.faceError);
	}

	private detectionCallOneResult = () => {

	}

	private smooth = (arr, windowSize, getter = (value) => value, setter) => {
		const get = getter;
		const result = [];


		// If the array is larger than 10 - we make it into 10 values


		var _arrLength = arr.length;

		if (_arrLength > 20) {
			arr = arr.slice(arr.length - 10, arr.length);
		}

		_arrLength = arr.length;

		for (let i = 0; i < _arrLength; i += 1) {
			const leftOffeset = i - windowSize
			const from = leftOffeset >= 0 ? leftOffeset : 0;
			const to = i + windowSize + 1;

			let count = 0;
			let sum = 0;
			for (let j = from; j < to && j < _arrLength; j += 1) {
				sum += get(arr[j]);
				count += 1;
			}

			result[i] = setter ? setter(arr[i], sum / count) : sum / count;
		}

		return result
	};

	private faceResult = (data: any) => {
		if (Globals.measureWebGLAnimateStarts === false) {
			Globals.stats.end();
		}

		//console.log(data);
		if (data) {

			var detectionOnX: number = this.videoWidth / 2;
			var detectionOnY: number = this.videoHeight / 2;

			if (data.box) {
				detectionOnX = data.box.left + data.box.width / 2;
				detectionOnY = data.box.top + data.box.height / 2;
			}


			if (data.detection && data.detection.box) {
				var detectionOnX: number = data.detection.box.left + data.detection.box.width / 2;
				//console.log(detectionOnX)
				// if Landmarks are available - we use those instead
				if (data.landmarks) {
					detectionOnX = data.landmarks.positions[33].x;
					detectionOnY = data.landmarks.positions[33].y;

					// Get Mouth Open percentage
					//	console.log(data.landmarks.positions)
					var upperLip = data.landmarks.positions[66].y;
					var lowerLip = data.landmarks.positions[62].y;

					var lipDistance = upperLip - lowerLip;

					var mouthOpenPercentage = lipDistance / 50;

					var p1 = data.landmarks.positions[39];
					var p0 = data.landmarks.positions[42];


					//setPoint(face.vertices, 39, p1); // left eye inner corner
					//	setPoint(face.vertices, 42, p0); // right eye outer corner

					var eyeDist = this.calcDistance(p0, p1);

					//setPoint(face.vertices, 62, p0); // mouth upper inner lip
					//setPoint(face.vertices, 66, p1); // mouth lower inner lip

					p1 = data.landmarks.positions[66];
					p0 = data.landmarks.positions[62];

					var mouthOpen = this.calcDistance(p0, p1);
					var mouthOpenPercentage = mouthOpen / eyeDist;

					mouthOpenPercentage -= 0.35; // remove smiling

					if (mouthOpenPercentage < 0) mouthOpenPercentage = 0;

					mouthOpenPercentage *= 3; // scale up a bit

					if (mouthOpenPercentage > 1.0) mouthOpenPercentage = 1.0;

					if (mouthOpenPercentage < 0.0) {
						mouthOpenPercentage = 0.0;
					}
					if (mouthOpenPercentage > 1.0) {
						mouthOpenPercentage = 1.0;
					}

					TweenMax.to(Globals.faceMouthOpenPercentageTweened, 1.2, {
						delay: 0.05,
						x: mouthOpenPercentage,
						ease: Power2.easeOut
					});

					this.faceMouthOpenArray.push(mouthOpenPercentage)

					Globals.faceMouthOpenPercentage = mouthOpenPercentage;

					Globals.faceMouthOpenPercentageSmoothed = this.smooth(this.faceMouthOpenArray, 2);
					Globals.faceMouthOpenPercentageSmoothed = Globals.faceMouthOpenPercentageSmoothed[Globals.faceMouthOpenPercentageSmoothed.length - 1];

					//console.log('Unsmoothed . ' + mouthOpenPercentage);
					//console.log('Globals.faceMouthOpenPercentageSmoothed . ' + Globals.faceMouthOpenPercentageSmoothed);
				}
			}


			// FIXME - The closer we get to the edges - the more we need to take the left / right position of the bounding box into account
			var getPercentageOnX: number = 1 - (detectionOnX / (this.videoWidth));
			getPercentageOnX = (getPercentageOnX - 0.5) * 1.0;
			getPercentageOnX = getPercentageOnX + 0.5;
			var getPercentageOnY: number = (detectionOnY / (this.videoHeight));
			getPercentageOnY = (getPercentageOnY - 0.5) * 1.0;
			getPercentageOnY = getPercentageOnY + 0.5;


			// Head Banger Position Start
			var bangActivated = false;
			//var previousPos = this.headBangerPositionYArray[this.headBangerPositionYArray.length - 1];

			this.headBangerPositionYArray.push(getPercentageOnY);

			if (this.headBangerPositionYArray.length > 12) {
				this.headBangerPositionYArray = this.headBangerPositionYArray.slice(this.headBangerPositionYArray.length - 12, this.headBangerPositionYArray.length);
			}

			//console.log(this.headBangerPositionYArray)


			var previousPosXPos = this.xFacePositionArray[this.xFacePositionArray.length - 1];
			var differenceOnX = previousPosXPos - getPercentageOnX

			//console.log(differenceOnX)

			if (differenceOnX < 0) {
				differenceOnX = differenceOnX * -1;
			}


			// FIXME - looks in a 10 point array if there is a -10 and then from that a +10 difference
			// FIXME - Basically look if there is a downwards peak and then a back to normal peak over an array with a length of 10.
			// FIXME - When found .- it should clear the array ?


			var lowestItemBeforeHighestItem = 8000;
			var lowestItemAfterHighestItem = 8000;

			var highestItem = 0;
			var highestItemPositionInArray = -1;


			// Find highest peak
			for (var i = 0; i < this.headBangerPositionYArray.length; i++) {
				var currentItem = this.headBangerPositionYArray[i];

				if (currentItem > highestItem) {
					highestItem = currentItem;
					highestItemPositionInArray = i;
				}
			}

			//	console.log(this.headBangerPositionYArray)

			// Find Lowest before the peak
			if (highestItemPositionInArray !== 0 && highestItemPositionInArray !== this.headBangerPositionYArray.length - 1) {
				for (var i = 0; i < highestItemPositionInArray; i++) {
					var currentItem = this.headBangerPositionYArray[i];

					if (currentItem < lowestItemBeforeHighestItem) {
						lowestItemBeforeHighestItem = currentItem;
					}
				}

				for (var i = highestItemPositionInArray + 1; i < this.headBangerPositionYArray.length; i++) {
					var currentItem = this.headBangerPositionYArray[i];

					if (currentItem < lowestItemAfterHighestItem) {
						lowestItemAfterHighestItem = currentItem;
					}
				}

				var calculatePeakDifferenceBeforeHighest = highestItem - lowestItemBeforeHighestItem;
				var calculatePeakDifferenceAfterHighest = highestItem - lowestItemAfterHighestItem;

				//console.log('calculatePeakDifferenceBeforeHighest : ' + calculatePeakDifferenceBeforeHighest);
				//	console.log('calculatePeakDifferenceAfterHighest : ' + calculatePeakDifferenceAfterHighest);
//differenceOnX < 0.005
				if (calculatePeakDifferenceBeforeHighest > 0.07 && calculatePeakDifferenceAfterHighest > 0.07) {
				//	console.log('BANG, calculatePeakDifferenceBeforeHighest' + calculatePeakDifferenceBeforeHighest + ' - calculatePeakDifferenceAfterHighest : ' + calculatePeakDifferenceAfterHighest)
					bangActivated = true;
					Globals.headbangDetected = true;
					//console.log('differenceOnX : ' + differenceOnX)
					//@ts-ignore:
					window.ga('send', 'event', 'Head Movement', 'Bang Head');

					this.headBangerPositionYArray = [];

				} else {
					Globals.headbangDetected = false;
				}
			}


			//	console.log('getPercentageOnY :; ' + getPercentageOnY);
			//	console.log(getPercentageOnX)


			Globals.webcamXPosition = getPercentageOnX;
			Globals.webcamYPosition = getPercentageOnY;
			Globals.faceExpressions = data.expressions;

			this.xFacePositionArray.push(getPercentageOnX);

			var smooth = this.smooth(this.xFacePositionArray, 1);
			Globals.faceXPositionSmoothed = smooth[smooth.length - 1];


			//	console.log(Globals.faceXPositionSmoothed)
			//Globals.webcamXPosition;//

			if (Globals.gameController) {
				//Globals.gameController.animate();
				Globals.gameController.updatePlayer(Globals.faceXPositionSmoothed);

				if (bangActivated === true) {
					Globals.gameController.headBangEffect();
				}

			}
			if (Globals.skull) {
				Globals.skull.animate(0);
			}
		}

		TweenMax.delayedCall(0.1, this.detection);
	};


	public resetMouthOpenDetect() {
		this.faceMouthOpenArray = [];
		Globals.faceXPositionSmoothed = 0;
	}

	public calcDistance(p0, p1) {
		return Math.sqrt(
			(p1.x - p0.x) * (p1.x - p0.x) +
			(p1.y - p0.y) * (p1.y - p0.y));
	}

	private faceError = (test: any) => {
		console.log('faceError : ' + test);
		//	this.detection();
	};


	public resize = () => {
		//	Globals.VIEW_MANAGER.resize();
		//	var videoWidth = window.innerWidth;
		//this._webcam.width = videoWidth;
	};
}

window.onload = () => {
	const main = new Main();
	(window as any).Main = main;
};
