import {AbstractView} from '../core/AbstractView';
import {MouseEvent} from '../../../lib/com/hellomonday/events/MouseEvent';
import {Linear, TweenMax, Power1, Power2, Power3} from "gsap/TweenMax";
import {Globals} from "../utils/Globals";
import {Rocks} from "../objects/Rocks";
import {GameLevels} from "../levels/GameLevels";
//@ts-ignore: Using Require to import ES5
let Howler = require('./../howler.js');

export class LevelsCompleted {

	private _element;

	private _bodyText;


	private _isShown: boolean = false;



	constructor(element) {
		this._element = element;
		this._bodyText = element.querySelector('.bodyText');

		this.init();
	}

	private init() {

	}

	public animateIn = () => {
		if (this._isShown === false) {
			this._isShown = true;
			this._element.style.display = 'block';

			var winnerText = 'Write to <a href="mailto:headbanger@hellomonday.com">headbanger@hellomonday.com</a> - with your score: ' + Globals.headBangerTop.getScore() + '<br>And we will make sure you get free tickets to our next concert.';
			this._bodyText.innerHTML = winnerText;

			TweenMax.to(this._element, 0.3, {y: 0, opacity: 1, ease: Power2.easeOut});
		}
	};

	public animateOut = () => {
		if (this._isShown === true) {
			this._isShown = false;
			TweenMax.to(this._element, 0.3, {y: 0, opacity: 0, ease: Power2.easeOut, onComplete: this.hide});
		}
	};

	public toggle = () => {
		if (this._isShown === true) {
			this.animateOut();
		} else {
			this.animateIn();
		}
		return this._isShown;
	};

	private hide = () => {
		this._element.style.display = 'none';
	};
}
