import {AbstractView} from '../core/AbstractView';
import {MouseEvent} from '../../../lib/com/hellomonday/events/MouseEvent';
import {Linear, TweenMax} from "gsap/TweenMax";
import * as THREE from "three";
import {Globals} from "../utils/Globals";
import {MeshBasicMaterial, MeshStandardMaterial} from "three";

export class Player {

	private  paddle;

	private playerGroup: THREE.Group;

	private playerMesh: THREE.Mesh;
	private playerWidth : number = 0.45;
	private playerDepth :number = 0.07 * 4;
	private scaleFactor: number = 1;

	constructor() {
		this.init();
	}

	private init() {

		this.playerGroup = new THREE.Group();

		var video = document.getElementById( '_webcam' );

		var texture = new THREE.VideoTexture(video);
		texture.minFilter = THREE.LinearFilter;
		texture.magFilter = THREE.LinearFilter;
		texture.format = THREE.RGBFormat;

		//map:texture
		/*var material = new THREE.MeshLambertMaterial({
			color: 0xffffff,
			opacity: 0.1,
			transparent: true,
			map: texture

		});*/

		// texture = Globals.getNamedTexture('smoke');

	/*var uniforms2 = {
			texture1: { type: "t", value: texture }
		};*/

		var uniforms = {
				tDiffuse: { value: texture },
				tSize:    { value: new THREE.Vector2( 256, 256 ) },
				center:   { value: new THREE.Vector2( 0.5, 0.5 ) },
				angle:    { value: 1.57 },
				scale:    { value: 1.0 }

			}

		var fragmentShader = [

			"uniform vec2 center;",
			"uniform float angle;",
			"uniform float scale;",
			"uniform vec2 tSize;",

			"uniform sampler2D tDiffuse;",

			"varying vec2 vUv;",

			"float pattern() {",

			"float s = sin( angle ), c = cos( angle );",

			"vec2 tex = vUv * tSize - center;",
			"vec2 point = vec2( c * tex.x - s * tex.y, s * tex.x + c * tex.y ) * scale;",

			"return ( sin( point.x ) * sin( point.y ) ) * 4.0;",

			"}",

			"void main() {",

			"vec4 color = texture2D( tDiffuse, vUv );",

			"float average = ( color.r + color.g + color.b ) / 3.0;",

			"gl_FragColor = vec4( vec3( average * 10.0 - 5.0 + pattern() ), 0.4 );",

			"}"

		].join( "\n" );

		var fireballShaderMaterial = new THREE.ShaderMaterial({
			uniforms: uniforms,
			vertexShader:document.getElementById('playerVertexShader').textContent,
			fragmentShader: fragmentShader,
			transparent: true
		});


		var geometry = new THREE.PlaneBufferGeometry(this.playerWidth, this.playerDepth);

		this.playerMesh = new THREE.Mesh(geometry, fireballShaderMaterial);
		this.playerMesh.scale.x = -1;
		this.playerMesh.rotation.x = THREE.Math.degToRad(-90);
		this.playerGroup.position.set(Globals.gameVariables.gameMapWidth / 2 - this.playerWidth / 2, 0, Globals.gameVariables.gameMapHeight - (0.01) / 2);

		this.playerGroup.add(this.playerMesh);

		var material = new THREE.MeshLambertMaterial({color: 0xffffff, flatShading: true});
		var geometry = new THREE.BoxBufferGeometry(this.playerWidth, .06, this.playerDepth / 10);
		this.paddle = new THREE.Mesh(geometry, material);
		this.paddle .position.z = (this.playerDepth / 2) - 0.01;
		this.paddle .position.y = 0.01;
		this.paddle .receiveShadow = false;
		this.paddle .castShadow = false;
		this.paddle .visible = false;

		this.playerGroup.add(this.paddle );
		material.side = THREE.DoubleSide;
	}

	public getElement() {
		return this.playerGroup;
	}

	public getWidth() {
		return this.playerWidth* this.scaleFactor;
	}

	public getDepth() {
		return 0.08;
	}

	public showPaddle() {
		this.paddle.visible = true;
	}

	public resetPosition() {
		this.playerGroup.position.set(Globals.gameVariables.gameMapWidth / 2 - this.playerWidth / 2, 0.15 * this.scaleFactor, Globals.gameVariables.gameMapHeight - (0.01 * this.scaleFactor) / 2);
	}

	public setScale(newScale: number) {
		this.scaleFactor = newScale;
		this.playerGroup.scale.set(this.scaleFactor * 1, 1, this.scaleFactor);
		this.resetPosition();
	}
}
