import {AbstractView} from '../core/AbstractView';
import {MouseEvent} from '../../../lib/com/hellomonday/events/MouseEvent';
import {Elastic, Linear, Quad, TweenMax, Power2, Power3} from "gsap/TweenMax";
import * as THREE from "three";
import {Globals} from "../utils/Globals";

//@ts-ignore: Using Require to import ES5
let Howler = require('./../howler.js');

export class Brick {

	private brick: THREE.Group;
	private brickMesh: THREE.Mesh;

	private xPos: number;
	private yPos: number;

	private brickDepth: number = 0.05;

	private numberOfHitsBeforeDestroyed: number = 1;
	private numberOfTimesHit: number = 0;

	private brickBounds: object;

	constructor(x: number, y: number, numberOfHitsToDestroy: number) {
		this.xPos = x;
		this.yPos = y;
		this.numberOfHitsBeforeDestroyed = numberOfHitsToDestroy;
		this.init();
	}

	private init() {
		this.brick = new THREE.Group();

		var useMaterial = Globals.brickMaterial0;
		if (this.numberOfHitsBeforeDestroyed === 1) {
			useMaterial = Globals.brickMaterial1;
		} else if (this.numberOfHitsBeforeDestroyed === 2) {
			useMaterial = Globals.brickMaterial2;
		}

		this.brickMesh = new THREE.Mesh(Globals.brickGeometry, useMaterial);
		//this.brickMesh.castShadow = true;
		//this.brickMesh.receiveShadow = true;
		this.brickMesh.position.x = Globals.brickWidthWithPadding / 2;
		this.brickMesh.position.y = ((this.brickDepth * this.numberOfHitsBeforeDestroyed) / 2);

		this.brickMesh.scale.y = this.numberOfHitsBeforeDestroyed;

		this.brickMesh.position.z = Globals.brickHeightWithPadding / 2;
		this.brick.position.set(this.xPos, 0, this.yPos);

		this.brick.visible = false;
		this.brick.add(this.brickMesh);


		//map: shadowTexture,
		const planeSize = Globals.brickWidthWithPadding * 1.3;
		const shadowGeo = new THREE.PlaneBufferGeometry(planeSize, planeSize / 2);
		var shadowMat = new THREE.MeshBasicMaterial({
			color: 0x000000,

			transparent: true,    // so we can see the ground
			depthWrite: false,    // so we don't have to sort
			opacity: 0.1
		});
		//map: Globals.getNamedTexture('blockShadow'),
		const shadowMesh = new THREE.Mesh(shadowGeo, shadowMat);
		shadowMesh.position.y = 0.001;  // so we're above the ground slightly
		shadowMesh.rotation.x = Math.PI * -.5;

		shadowMesh.position.x = Globals.brickWidthWithPadding * 0.4;
		shadowMesh.position.z = 0.07;


		this.brick.add(shadowMesh);
		/*const shadowSize = sphereRadius * 4;
		shadowMesh.scale.set(shadowSize, shadowSize, shadowSize);
		base.add(shadowMesh);*/


		//this.onLoad(Globals.objectSkull.clone(true));
	}

	private destroyElementAnimation(fromDirection: string, type: string) {
		if (type === 'bang') {
			TweenMax.to(this.brickMesh.position, 0.3, {

				x: this.brickMesh.position.x + Math.random() * 4 - 2,
				y: Math.random() * 1 + 1,
				z: Math.random() * 3 + 3,
				ease: Power2.easeIn,
				onComplete: this.removeElement
			});
			TweenMax.to(this.brickMesh.rotation, 0.3, {
				x: Math.random() * 2 - 1,
				y: Math.random() * 1 - 1,
				z: Math.random() * 1 - 1,
				ease: Power2.easeIn
			});
		}
		else {
			TweenMax.to(this.brickMesh.scale, 0.15, {
				y: 0.01,
				ease: Power2.easeIn,
				onComplete: this.removeElement
			});
		}

	}

	private removeElement = () => {
		this.brick.parent.remove(this.brick);
		this.brick.remove(this.brickMesh);
		this.brickMesh.geometry.dispose();
		this.brickMesh = undefined;
		this.brick = undefined;
	};

	public isHit(fromDirection: string, forceRemove: boolean, type: string) {

		var randomSound = Math.ceil(Math.random() * 3);
		var sound = new Howl({
			src: ['assets/sounds/tam' + randomSound + '.mp3']
		});
		sound.play();


		var shouldDestroy: Boolean = false;

		this.numberOfTimesHit++;

		if (forceRemove) {
			this.numberOfTimesHit = this.numberOfHitsBeforeDestroyed;
		}

		if (this.numberOfTimesHit === this.numberOfHitsBeforeDestroyed) {
			shouldDestroy = true;
			this.destroyElementAnimation(fromDirection, type);
			Globals.headBangerTop.updateScore(666);
		} else {
			// We tween the scale height
			var calcNewScale = this.numberOfHitsBeforeDestroyed - this.numberOfTimesHit;
			//console.log(calcNewScale)
			TweenMax.to(this.brickMesh.scale, 0.15, {
				y: calcNewScale,
				ease: Power3.easeIn
			});

			var calcNewYPos = ((this.brickDepth * calcNewScale) / 2);
			TweenMax.to(this.brickMesh.position, 0.15, {
				y: calcNewYPos,
				ease: Power3.easeIn
			});
		}
		return shouldDestroy;
	}

	public getElement() {
		return this.brick;
	}

	public getBounds() {
		return this.brickBounds;
	}

	public storeBrickBounds(bounds) {
		this.brickBounds = bounds;
	}

}
