import {Linear, Power1, Power2, Power3, TweenMax, Circ} from "gsap/TweenMax";
import {Globals} from "../utils/Globals";
import * as THREE from "three";
import {Player} from "../game/Player";
import {MeshBasicMaterial} from "three";

//@ts-ignore: Using Require to import ES5
let Howler = require('./../howler.js');

export class IntroFlowModule {

	private headBangElement;
	private skullLeftElement;
	private skullRightElement;
	private pentagramElement;

	private checkingLeftSide: boolean = false;
	private checkingRightSide: boolean = false;
	private checkingForHeadBang: boolean = false;

	private mainSceneGroup;

	private dracarisMesh: THREE.Mesh;
	private helloMondayMesh: THREE.Mesh;

	private movePlayer: boolean = false;

	private allowAnimation: boolean = false;


	constructor(mainSceneGroup) {
		this.mainSceneGroup = mainSceneGroup;
		this.init();
	}

	private init() {
		this.addMeshesToIntroFlow();
		this.setupVideoCameraScreen();
	};

	private addMeshesToIntroFlow() {
		var logoRatioDracaris = 512 / 1024;
		var geometry = new THREE.PlaneBufferGeometry(1, logoRatioDracaris);
		var material = new THREE.MeshBasicMaterial({map: Globals.getNamedTexture('logo_dracaris'), transparent: true});
		this.dracarisMesh = new THREE.Mesh(geometry, material);
		this.dracarisMesh.position.z = -700;
		//this.dracarisMesh.rotation.x = THREE.Math.degToRad(90);
		this.mainSceneGroup.add(this.dracarisMesh);


		var logoRatioHelloMonday = 512 / 1024;
		var geometry = new THREE.PlaneBufferGeometry(1, logoRatioHelloMonday);
		var material = new THREE.MeshBasicMaterial({
			map: Globals.getNamedTexture('logo_hellomonday'),
			transparent: true
		});
		this.helloMondayMesh = new THREE.Mesh(geometry, material);
		this.helloMondayMesh.position.z = -600;
		this.mainSceneGroup.add(this.helloMondayMesh);

		Globals.player = new Player();
		Globals.player.getElement().rotation.x = THREE.Math.degToRad(90);
		/*Globals.player.getElement().rotation.z = THREE.Math.degToRad(10);
		Globals.player.getElement().rotation.y = THREE.Math.degToRad(10);*/

		Globals.player.getElement().position.y = 0;
		Globals.player.getElement().position.x = 0;

		Globals.player.getElement().position.z = -400;
		this.mainSceneGroup.add(Globals.player.getElement());
	}

	private setupVideoCameraScreen = () => {

		var elementSizes = 0.08;

		var geometry = new THREE.PlaneBufferGeometry(elementSizes, elementSizes);
		var material = new THREE.MeshBasicMaterial({map: Globals.getNamedTexture('skull_left'), transparent: true});
		this.skullLeftElement = new THREE.Mesh(geometry, material);
		this.skullLeftElement.position.z = Globals.player.getElement().position.z + 0.2;
		this.skullLeftElement.position.x = -0.4;
		this.mainSceneGroup.add(this.skullLeftElement);
		TweenMax.to(this.skullLeftElement.position, 0.4, {repeat:- 1, yoyo: true, x: '+=0.01', ease: Power1.easeInOut});

		var geometry = new THREE.PlaneBufferGeometry(elementSizes, elementSizes);
		var material = new THREE.MeshBasicMaterial({map: Globals.getNamedTexture('skull_right'), transparent: true});
		this.skullRightElement = new THREE.Mesh(geometry, material);
		this.skullRightElement.position.z = Globals.player.getElement().position.z + 0.2;
		this.skullRightElement.position.x = 0.2;
		this.mainSceneGroup.add(this.skullRightElement);

		var geometry = new THREE.PlaneBufferGeometry(elementSizes, elementSizes);
		var material = new THREE.MeshBasicMaterial({map: Globals.getNamedTexture('skull_bang'), transparent: true});
		this.headBangElement = new THREE.Mesh(geometry, material);
		this.headBangElement.position.z = Globals.player.getElement().position.z + 0.2;
		this.headBangElement.position.y = -0.20;
		this.mainSceneGroup.add(this.headBangElement);


		var geometry = new THREE.PlaneBufferGeometry(0.6, 0.6);
		var material = new THREE.MeshBasicMaterial({map: Globals.getNamedTexture('pentagram'), transparent: true});
		this.pentagramElement = new THREE.Mesh(geometry, material);
		this.pentagramElement.position.z = Globals.player.getElement().position.z - 0.2;
		this.pentagramElement.position.x = 0;
		//	this.mainSceneGroup.add(this.pentagramElement);


		TweenMax.set([this.skullLeftElement.rotation, this.skullRightElement.rotation, this.headBangElement.rotation], {y: Math.PI * -0.5});
		TweenMax.set([this.skullLeftElement.scale, this.skullRightElement.scale, this.headBangElement.scale], {
			x: 0.01,
			y: 0.01,
			z: 0.01
		});


	};

	public start() {
		var sound = new Howl({
			src: ['assets/sounds/ambience-thunder-storm-light.mp3']
		});
		sound.play();

		TweenMax.delayedCall(1, Globals.headBangerTop.animateIn);

		if (Globals.debugSkipIntro) {
			Globals.startWebCamFunction();
			Globals.faceDetectionTurnedOff = false;
			this.bothCirclesFound();

		} else {
			this.zoomToDracarisLogo();
		}

	}

	private zoomToDracarisLogo() {
		//this.dracarisMesh.rotation.x = THREE.Math.degToRad(90);

		TweenMax.to(this.mainSceneGroup.position, 5 * Globals.speedUpTextIntroWithFactor, {
			ease: Power1.easeOut,
			z: this.dracarisMesh.position.z * -1 + 6,
			onComplete: this.zoomToHelloMondayLogo
		});


		/*TweenMax.to(this.dracarisMesh.rotation, 1 * Globals.speedUpTextIntroWithFactor, {
			delay: 6 * Globals.speedUpTextIntroWithFactor,
			ease: Circ.easeInOut,
			x: 0
		});*/
	}

	private zoomToHelloMondayLogo = () => {
		//this.helloMondayMesh.rotation.x = THREE.Math.degToRad(90);

		// Fade out Dracaris logo
		TweenMax.to(this.dracarisMesh.material, 1 * Globals.speedUpTextIntroWithFactor, {
			ease: Power1.easeIn,
			delay: 1,
			opacity: 0,
			onComplete: this.removeMesh,
			onCompleteParams: [this.dracarisMesh]
		});

		TweenMax.to(this.mainSceneGroup.position, 5 * Globals.speedUpTextIntroWithFactor, {
			delay: 1 * Globals.speedUpTextIntroWithFactor,
			ease: Circ.easeInOut,
			z: this.helloMondayMesh.position.z * -1 + 6,
			onComplete: this.zoomToVideoCameraElement
		});

	};

	private zoomToVideoCameraElement = () => {

		// Fade out logo
		TweenMax.to(this.helloMondayMesh.material, 1 * Globals.speedUpTextIntroWithFactor, {
			ease: Power1.easeIn,
			delay: 1,
			opacity: 0,
			onComplete: this.removeMesh,
			onCompleteParams: [this.helloMondayMesh]
		});

		//TweenMax.to(Globals.mainScene.getDragonMesh().position, 6, {delay: 2, x:20, y: -7, z: -5, ease: Linear.easeNone});


		TweenMax.to(this.mainSceneGroup.position, 5 * Globals.speedUpTextIntroWithFactor, {
			delay: 1 * Globals.speedUpTextIntroWithFactor,
			ease: Circ.easeInOut,
			z: Globals.player.getElement().position.z * -1 + 10,
			onComplete: this.showingVideoCameraElement
		});

		// auto rotate player
		/*	TweenMax.to(Globals.player.getElement().rotation, 5, {
                ease: Circ.easeInOut,
                yoyo: true,
                repeat: -1,
                y: '+=' + 0.2,
                x: '+=' + 0.2,
                z: '+=' + 0.2,
            });*/
		this.movePlayer = true;


		//Globals.rain.stopFlashing();
	};

	private removeMesh = (meshToRemove) => {
		this.mainSceneGroup.remove(meshToRemove);
		meshToRemove.geometry.dispose();
		meshToRemove.material.dispose();
		meshToRemove = undefined;
	};

	private showingVideoCameraElement = () => {

		Globals.startWebCamFunction(this.webcamAllowed);
		Globals.faceDetectionTurnedOff = false;


	};

	private webcamAllowed = () => {

		TweenMax.to(this.skullLeftElement.rotation, 1, {delay: 0.5, y: 0, ease: Circ.easeOut});
		TweenMax.to(this.skullLeftElement.scale, 0.5, {
			delay: 0.5,
			x: 1,
			y: 1,
			z: 1,
			ease: Circ.easeOut,
			onComplete: this.leftCircleReady
		});


		Globals.instructionText.changeText('MOVE YOUR HEAD TO THE LEFT');

		this.allowAnimation = true;
	}


	private leftCircleReady = () => {
		this.checkingLeftSide = true;
	};

	public animate = () => {
		if (this.allowAnimation) {

			let getPositionX = Globals.faceXPositionSmoothed;

			if (this.checkingLeftSide === true) {
				if (getPositionX < 0.4) {
					this.hideCircleLeftSide();
					this.checkingLeftSide = false;
				}
			} else if (this.checkingRightSide === true) {
				if (getPositionX > 0.65) {
					this.hideCircleRightSide();
					this.checkingRightSide = false;
				}
			} else if (this.checkingForHeadBang === true) {
				if (Globals.headbangDetected === true) {
					this.animateOutCircle();
					this.checkingForHeadBang = false;
				}
			}


			if (this.movePlayer) {
				if (Globals.faceXPositionSmoothed) {
					var newPos = Globals.faceXPositionSmoothed - 0.5;
					TweenMax.to(Globals.player.getElement().position, 0.3, {
						delay: 0.0,
						x: newPos * 2,
						ease: Power1.easeInOut
					})
					/*	TweenMax.to(Globals.mainScene.getCamera().rotation, 0.3, {
                            y: (newPos / 4) * -1,
                            ease: Power1.easeInOut
                        });*/
				}
			}
		}
	};

	private hideCircleLeftSide = () => {
		console.log('hide')
		TweenMax.to(this.skullLeftElement.rotation, 0.5, {
			y: Math.PI * 0.5,
			onComplete: this.showCircleInRightSide,
			ease: Circ.easeIn
		});
		TweenMax.to(this.skullLeftElement.scale, 0.5, {x: 0.01, y: 0.01, z: 0.01, ease: Circ.easeIn});

		var sound = new Howl({
			src: ['assets/sounds/C5_power_chord.mp3']
		});
		sound.stereo = -1;
		sound.seek(0);
		sound.play();
		Globals.instructionText.animateOut();
	};

	private showCircleInRightSide = () => {
		if (this.checkingRightSide === false) {
			Globals.instructionText.changeText('MOVE YOUR HEAD TO THE RIGHT');

			this.checkingRightSide = true;
			TweenMax.killTweensOf(this.skullLeftElement.position);
			TweenMax.set(this.skullRightElement.position, {x: 0.4, ease: Power1.easeOut});
			TweenMax.to(this.skullRightElement.position, 0.4, {repeat:- 1, yoyo: true, x: '-=0.01', ease: Power1.easeInOut});


			TweenMax.to(this.skullRightElement.rotation, 1, {
				y: 0,
				ease: Circ.easeOut
			});
			TweenMax.to(this.skullRightElement.scale, 0.5, {
				x: 1,
				y: 1,
				z: 1, ease: Circ.easeOut
			});
		}
	};

	private hideCircleRightSide = () => {

		Globals.instructionText.animateOut();
		TweenMax.to(this.skullRightElement.rotation, 0.5, {
			y: Math.PI * 0.5,
			onComplete: this.lookForHeadBang,
			ease: Circ.easeIn
		});
		TweenMax.to(this.skullRightElement.scale, 0.5, {x: 0.01, y: 0.01, z: 0.01, ease: Circ.easeIn});
		var sound = new Howl({
			src: ['assets/sounds/C5Sharp_power_chord.mp3']
		});
		sound.stereo = 1;
		sound.seek(0);
		sound.play();
		Globals.instructionText.animateOut();
	};


	private lookForHeadBang = () => {
		TweenMax.set(this.headBangElement.position, {x: 0, ease: Power1.easeOut});

		//TweenMax.to(this.headBangElement.rotation, 1, {z: Math.PI, ease: Circ.easeOut});

		TweenMax.killTweensOf(this.skullRightElement.position);

		TweenMax.to(this.headBangElement.rotation, 1, {
			y: 0,
			x: 0,
			z: 0,
			ease: Circ.easeOut
		});

		TweenMax.to(this.headBangElement.scale, 1, {
			x: 1,
			y: 1,
			z: 1,
			onComplete: this.headBangBoxAnimatedIn,
			ease: Circ.easeOut
		});
	};

	private headBangBoxAnimatedIn = () => {

		Globals.instructionText.changeText('NOW DO A HEAD BANG');
		TweenMax.to(this.headBangElement.scale, 0.4, {repeat:- 1, yoyo: true, x: 1.2, y: 1.2, z: 1.2, ease: Power1.easeInOut});
		this.checkingForHeadBang = true;
	};

	private animateOutCircle = () => {
		Globals.instructionText.animateOut();
		var getCamera = Globals.mainScene.getCamera();
		var currentCameraPos = getCamera.position.z;


		TweenMax.to(getCamera.position, 0.1, {delay: 0.0, z: currentCameraPos + 1.5, ease: Power2.easeOut});
	//	TweenMax.to(getCamera.rotation, 0.1, {delay: 0.05, z: 0.4, ease: Power2.easeOut});
		TweenMax.to(getCamera.position, 0.1, {delay: 0.1, z: currentCameraPos, ease: Power2.easeOut});
		//TweenMax.to(getCamera.rotation, 0.1, {delay: 0.1, z: 0, ease: Power2.easeOut});

		//Globals.floatingIslands.animateAllRocks();


		TweenMax.to(this.headBangElement.rotation, 0.5, {
			y: Math.PI * 0.5,
			ease: Circ.easeIn
		});
		TweenMax.killTweensOf(this.headBangElement.scale);
		TweenMax.to(this.headBangElement.scale, 0.5, {x: 0.01, y: 0.01, z: 0.01, ease: Circ.easeIn});
		var sound = new Howl({
			src: ['assets/sounds/FOS2_Kick_053.mp3']
		});
		sound.seek(0);
		sound.play();

		this.bothCirclesFound();

	};



	private bothCirclesFound = () => {
		Globals.instructionText.animateOut();


		// MOVE OUT TO REVEAL SKULL
		TweenMax.killTweensOf(Globals.mainScene.getSceneGroup().position);
		TweenMax.to(Globals.mainScene.getSceneGroup().position, 5 * Globals.speedUpTextIntroWithFactor, {
			delay: 1 * Globals.speedUpTextIntroWithFactor,
			ease: Circ.easeInOut,
			z: 0,
			onComplete: this.skullShownStartDetection
		});

		// Fade out "Player"
		/*TweenMax.to(Globals.player.getElement().material, 1 * Globals.speedUpTextIntroWithFactor, {
			ease: Power1.easeIn,
			delay: 1,
			opacity: 0,
			onComplete: this.hidePlayer
		});*/
		TweenMax.delayedCall(2 * Globals.speedUpTextIntroWithFactor, this.hidePlayer);

		Globals.faceDetectionTurnedOff = true;
	};

	private hidePlayer = () => {
		if (this.headBangElement.parent) {
			this.headBangElement.parent.remove(this.headBangElement);
		}
		Globals.player.getElement().visible = false;
	}

	private skullShownStartDetection = () => {
	//	console.log('skullShownStartDetection()')
		this.movePlayer = false;
		//	TweenMax.to(Globals.mainScene.getAmbientLight(), 0.5, {intensity: 0});
		Globals.faceDetectionLookForLandmarks = true;
		Globals.faceDetectionTurnedOff = false;
		Globals.instructionText.changeText('OPEN YOUR MOUTH');
		Globals.skull.showSkull(this.mouthOpened);


	};

	private mouthOpened = () => {
		this.allowAnimation = false;
	}
}
