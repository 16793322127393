import * as THREE from 'three';
import {Linear, Quad, TweenMax, Quart, Power1, Power2, Power3} from "gsap/TweenMax";
import {Ball} from "../game/Ball";
import {Player} from "../game/Player";
import {Brick} from "../game/Brick";
import {Globals} from "../utils/Globals";
import {GameLevels} from "../levels/GameLevels";
import {Line} from "three";

(window as any).THREE = THREE;


//@ts-ignore: Using Require to import ES5
require('./../OBJLoader.js');

//@ts-ignore: Using Require to import ES5
var PolyK = require('./../polyk.js');

/*
//@ts-ignore: Using Require to import ES5
require('./SubdivisionModifier.js');
//@ts-ignore: Using Require to import ES5
require('./TessellateModifier.js');
//@ts-ignore: Using Require to import ES5
require('./OBJLoader.js');
//@ts-ignore: Using Require to import ES5
require('./MTLLoader.js');*/

export class GameController {
	// Gameboard
	private gameBoardContainer: THREE.Group;
	private gameBoardInnerContainer: THREE.Group;
	// Gameboard sides and container
	private leftSide: THREE.Mesh;
	private rightSide: THREE.Mesh;
	private backSide: THREE.Mesh;
	private gameBackground: THREE.Mesh;

	// On field items
	private ballClass: Ball;
	private ballElement: THREE.Group;
	// Direction X
	private ball_dx: number;
	// Direction Y
	private ball_dy: number;
	private playerClass: Player;
	private playerElement: THREE.Group;
	private bricks: Array<Brick>;

	// Game Statuses
	private gamePaused: Boolean = true;
	private ballSpeed: number = 4;
	private gameState: string = 'waiting';
	private allowBallToMove: Boolean = true;
	private playerPosition: Object = {x: 0};
	private firstBallRelease: boolean = true;
	private ballHitPlayer: boolean = true;
	private allBricksRemoved: boolean = false;

	// Keyboard
	private keyArrowLeftDown: boolean = false;
	private keyArrowRightDown: boolean = false;

	private hasPaddleBeenHit: boolean = false;

	private allowAnimation: boolean = false;

	private _firstRun: boolean = true;

	private _useScene;

	private levelRestarted: boolean = true;

	constructor(useScene) {
		this._useScene = useScene;
		this.init();
	}

	private init() {
		this.gameBoardContainer = new THREE.Group;
		this.gameBoardContainer.rotation.x = 1.7;
		//this.gameBoardContainer.position.z = -0.4;
		this._useScene.add(this.gameBoardContainer);


		var material = new THREE.MeshPhongMaterial({
			flatShading: true,
			color: 0x0f0f0f,


			shininess: 10
		});
//roughness: 0.2,
		//	metalness: 0.3,

		this.gameBoardContainer.visible = false;
		this.gameBoardInnerContainer = new THREE.Group();
		this.gameBoardInnerContainer.position.set((Globals.gameVariables.gameMapWidth / 2) * -1, 0, (Globals.gameVariables.gameMapHeight / 2) * -1);

		this.gameBoardContainer.add(this.gameBoardInnerContainer);

		// Field
		var fieldGeometry = new THREE.BoxBufferGeometry(Globals.gameVariables.gameMapWidth, .05, Globals.gameVariables.gameMapHeight, 1, 1, 1);
		this.gameBackground = new THREE.Mesh(fieldGeometry, material);
		this.gameBackground.receiveShadow = false;
		this.gameBackground.position.set(Globals.gameVariables.gameMapWidth / 2, -0.025, Globals.gameVariables.gameMapHeight / 2);
		this.gameBoardInnerContainer.add(this.gameBackground);

		// Build Level!
		this.bricks = [];
		var leftSideGeometry = new THREE.BoxBufferGeometry(Globals.gameVariables.gameMapWidth / 10, .05, Globals.gameVariables.gameMapHeight, 1, 1, 1);
		var leftSide: THREE.Mesh = new THREE.Mesh(leftSideGeometry, material);
		leftSide.position.set(-0.025, (Globals.gameVariables.gameMapWidth / 10 / 2) - 0.05, Globals.gameVariables.gameMapHeight / 2);

		leftSide.receiveShadow = false;
		leftSide.rotation.z = THREE.Math.degToRad(-90);
		this.leftSide = leftSide;
		this.gameBoardInnerContainer.add(leftSide);

		var rightSideGeometry = new THREE.BoxBufferGeometry(Globals.gameVariables.gameMapWidth / 10, .05, Globals.gameVariables.gameMapHeight, 1, 1, 1);
		var rightSide: THREE.Mesh = new THREE.Mesh(rightSideGeometry, material);
		rightSide.position.set(Globals.gameVariables.gameMapWidth + 0.025, (Globals.gameVariables.gameMapWidth / 10 / 2) - 0.05, Globals.gameVariables.gameMapHeight / 2);
		rightSide.receiveShadow = false;
		rightSide.rotation.z = THREE.Math.degToRad(90);
		this.rightSide = rightSide;
		this.gameBoardInnerContainer.add(rightSide);


		var backSideGeometry = new THREE.BoxBufferGeometry(Globals.gameVariables.gameMapWidth / 1 + 0.05 * 2, .05, Globals.gameVariables.gameMapWidth / 10, 1, 1, 1);
		var backSide: THREE.Mesh = new THREE.Mesh(backSideGeometry, material);
		backSide.userData.defaultY = (Globals.gameVariables.gameMapWidth / 10 / 2) - .05;
		backSide.position.set(Globals.gameVariables.gameMapWidth / 2, backSide.userData.defaultY, -0.025);
		backSide.receiveShadow = false;
		backSide.rotation.x = THREE.Math.degToRad(-90);
		this.backSide = backSide;
		this.gameBoardInnerContainer.add(backSide);


		// Add Light
		var newLight = new THREE.DirectionalLight(0xffffff, 0.5);
		this.gameBoardInnerContainer.add(newLight);


		// Player


		//	this.gameOver();
	};

	public firstRun = () => {
		this.playerClass = Globals.player;
		TweenMax.killTweensOf(this.playerClass.getElement().position);
		this.playerElement = this.playerClass.getElement();
		this.playerClass.getElement().visible = true;

		this.playerClass.resetPosition();
		this.gameBoardInnerContainer.add(this.playerElement);

		/*var controls = new THREE.OrbitControls( this.camera, this.renderer.domElement );
		controls.target.set( 0, 10, 0 );
		controls.update();*/

		if (Globals.allowKeyboardCheat === true) {
			document.addEventListener('keydown', this.keyDown);
			document.addEventListener('keyup', this.keyUp);
		}




		Globals.faceMouthOpenPercentage = 0;
		Globals.faceMouthOpenPercentageTweened.x = 0;

		var skull = Globals.skull.getMesh();
		this.gameBoardInnerContainer.add(Globals.skull.getMesh());
		skull.scale.set(0.2, 0.2, 0.2);
		skull.position.z = -5;
		skull.position.y = 1.65;
		skull.position.x = Globals.gameVariables.gameMapWidth / 2;
		Globals.skull.resetSkeletonScale();
		Globals.skull.closeMouth();

		Globals.player.showPaddle();
	};

	public animateGameboardIn = () => {
		//console.log('animateGameboardIn')

		if (this._firstRun === true) {
			this.firstRun();
			this._firstRun = false;
		}

		this.allBricksRemoved = false;

		TweenMax.to(Globals.mainScene.getAmbientLight(), 0.4, {intensity: 0});
		TweenMax.to(Globals.mainScene.getPointLight(), 0.4, {intensity: 0});

		// Get the Ball
		this.ballClass = Globals.ball;

		this.ballElement = this.ballClass.getBall();
		TweenMax.killTweensOf(this.ballElement.scale);
		TweenMax.killTweensOf(this.ballElement.position);

		this.ballElement.position.set(0, 0, 0)
		this.ballElement.scale.set(0.0017, 0.0017, 0.0017);
		this.ballElement.position.y = this.ballClass.getBallSize() + 0.02;


		this.gameBoardInnerContainer.add(this.ballElement);
		// Do board buildup

		TweenMax.set(this.gameBoardContainer.scale, {x: 0.2, y: 0.2, z: 0.2});

		this.gameBoardContainer.visible = true;
		TweenMax.set(this.gameBoardContainer.position, {z: -4});
		TweenMax.set(this.gameBoardContainer.rotation, {x: 1.4, y: 2.2, z: -2.2});

		TweenMax.set(this.backSide.position, {y: this.backSide.userData.defaultY - 0.05});




		TweenMax.killTweensOf(Globals.skull.getMesh().position);

		TweenMax.set(Globals.skull.getMesh().position, {z: -0.4, y: 0.45});


		TweenMax.to(this.gameBoardContainer.scale, 3, {
			x: 1,
			y: 1,
			z: 1,
			ease: Power3.easeInOut
		});

		/*TweenMax.to(this.gameBoardContainer.rotation, 3, {
			x: 0.3,
			y: 0,
			z: 0,
			ease: Power3.easeInOut
		});*/

		/*TweenMax.to(this.gameBoardContainer.rotation, 3, {
			x: 0.8,
			y: 0.1,
			z: 0,
			ease: Power3.easeInOut
		});*/

		TweenMax.to(this.gameBoardContainer.rotation, 3, {
			x: 0.4,
			y: 0,
			z: 0,
			ease: Power3.easeInOut
		});

		TweenMax.to(this.gameBoardContainer.position, 3, {
			z: 5,
			ease: Power3.easeInOut
		});


		TweenMax.delayedCall(1, this.createLevel);
		TweenMax.delayedCall(5, this.gameOverRestart);
		TweenMax.delayedCall(4, Globals.levelScreen.animateIn, [this.showOpenMouthMessage]);


		this.gameBoardContainer.visible = true;
		this.allowAnimation = true;

		this.gameState = 'waiting';
		this.placeBallInSkull();
	};


	private bricksRemoved() {
		this.allBricksRemoved = true;
		TweenMax.to(this.backSide.position, 1, {y: this.backSide.userData.defaultY - 0.5})
	}

	private placeBallInSkull = () => {
		this.ballElement.position.x = Globals.gameVariables.gameMapWidth / 2;
		this.ballElement.position.z = -0.4;
		this.ballElement.position.y = 0.25;
	}

	private gameOver = () => {
		//console.log('gameOver');

		window['ga']('send', 'event', 'Lost Life', 'In level ' + Globals.gameVariables.currentLevel);

		Globals.headBangerTop.updateScore(-666);
		Globals.rain.startFlashing();

		this.gameState = 'waiting';
		this.gamePaused = true;

		var newIntensity = 0;
		TweenMax.killTweensOf(Globals.ball.getLight());
		TweenMax.to(Globals.ball.getLight(), 0.3, {
			intensity: newIntensity,
			ease: Power1.easeIn, onComplete: this.gameOverRestart
		});
	};

	private gameOverRestart = () => {
	//	console.log('gameOverRestart')
		this.placeBallInSkull();

		Globals.faceMouthOpenPercentage = 0;
		Globals.faceMouthOpenPercentageTweened.x = 0;
		//	Globals.faceXPositionSmoothed = 0.5;
		Globals.faceMouthOpenPercentageSmoothed = 0;

		Globals.main.resetMouthOpenDetect();

		Globals.faceDetectionTurnedOff = false;
		Globals.faceDetectionLookForLandmarks = true;
		TweenMax.to(this.backSide.position, 1, {y: this.backSide.userData.defaultY - 0.5});
		this.firstBallRelease = true;
		Globals.skull.lostLifeRestartWithBall(this.startGame, this.backSide, this.allBricksRemoved);

		if (this.levelRestarted === true) {
		//	TweenMax.delayedCall(6, this.showOpenMouthMessage);
			this.levelRestarted = false;
		}
		else {
			Globals.instructionText.changeText('TRY AGAIN, OPEN YOUR MOUTH');
		}


	};

	private showOpenMouthMessage = () => {
		Globals.instructionText.changeText('TO BEGIN, OPEN YOUR MOUTH');
	}

	public startGame = () => {

		Globals.instructionText.animateOut();

		this.gameState = 'playing';
		this.gamePaused = false;
		this.hasPaddleBeenHit = false;

		Globals.levelScreen.animateOut();


		//console.log('angle : ' + THREE.Math.radToDeg(Globals.skull.getSkullRotation().y));
		var angle = Globals.skull.getSkullRotation().y * -1 + (Math.PI * 0.5);


		var px = this.ballSpeed * Math.cos(angle); // <-- that's the maths you need
		var py = this.ballSpeed * Math.sin(angle);


		//console.log('px : ' + px);
		//console.log('py : ' + py);

		this.ball_dx = (px / 100) * GameLevels.data[Globals.gameVariables.currentLevel].levelBallSpeed;
		this.ball_dy = (py / 100) * GameLevels.data[Globals.gameVariables.currentLevel].levelBallSpeed;

		//this.ball_dx = (Math.random() * 0.01 + 0.01) * this.ballSpeed;
		//	this.ball_dy = (Math.random() * 0.01 + 0.01) * this.ballSpeed;
		Globals.faceDetectionLookForLandmarks = false;
		Globals.faceMouthOpenPercentage = 0;
		Globals.faceMouthOpenPercentageTweened.x = 0;

	//	Globals.rain.stopFlashing();
	};

	private exitLevel() {
		this.levelRestarted = true;
		this.gameState = 'waiting';
		this.gamePaused = true;
		this.allowAnimation = false;

		Globals.levelScreen.animateOut();

		Globals.gameVariables.currentLevel++;
		Globals.faceDetectionTurnedOff = true;
		Globals.main.resetMouthOpenDetect();


		if (Globals.gameVariables.currentLevel === 13)
		{
			Globals.levelsCompleted.animateIn();
		}
		else {
			var duration = 1;
			var easeType = Power1.easeOut;
			/*	TweenMax.to(Globals.skull.getMesh().position, duration, {
                    delay: 0,
                    z: Globals.skull.getMesh().position.z + 5,
                    ease: easeType
                });
                TweenMax.to(this.gameBoardContainer.position, duration, {
                    z: this.gameBoardContainer.position.z + 5,
                    ease: easeType,
                    onComplete: this.exitLevelDone
                });*/

			TweenMax.to(this.gameBoardContainer.scale, duration, {x: 0.1, y: 0.1, z: 0.1, ease: easeType});

			this.gameBoardContainer.visible = true;
			TweenMax.to(this.gameBoardContainer.position, duration, {z: -4, ease: easeType});
			TweenMax.to(this.gameBoardContainer.rotation, duration, {
				x: 1.4,
				y: 2.2,
				z: -2.2,
				ease: easeType,
				onComplete: this.exitLevelDone
			});
		}




	}

	private exitLevelDone = () => {
		this.gameBoardContainer.visible = false;
		Globals.mainScene.gotoIsland(true);
	};


	private createLevel = () => {
		var levelData = GameLevels.data[Globals.gameVariables.currentLevel].levelLayoutData;
		levelData = levelData.split(' ');

		window['ga']('send', 'event', 'Start Level', Globals.gameVariables.currentLevel);


		Globals.player.setScale(GameLevels.data[Globals.gameVariables.currentLevel].paddleSize);

		if (window.location.href.indexOf('?testLevel') > -1) {
			var returnFromPrompt = prompt('Copy / Paste content from spreadsheet to test the level');
			if (returnFromPrompt !== null) {
				levelData = returnFromPrompt;
				levelData = levelData.split(' ');
			}
		}

		var sortedLevelData = [];
		var currentArrayCount = -1;
		for (var i = 0; i < levelData.length; i++) {
			if (i % 11 === 0) {
				currentArrayCount++;
				sortedLevelData.push(new Array());
			}
			var currentData = Number(levelData[i]);
			sortedLevelData[currentArrayCount].push(currentData);
		}

		levelData = sortedLevelData;

		var rowCount = 0;
		var startYPos = 0;
		var startXPos = 0;
		var xPos = startXPos;
		var xPosCount = 0;

		for (var i = 0; i < levelData.length; i++) {
			var currentRow = levelData[i];

			for (var p = 0; p < currentRow.length; p++) {
				var currentBrick = currentRow[p];
				if (currentBrick >= 1) {
					var brickClass: Brick = new Brick(startXPos + xPosCount * Globals.gameVariables.brickWidth, rowCount * +Globals.gameVariables.brickHeight + startYPos, currentBrick);
					var brickElement: THREE.Group = brickClass.getElement();
					brickElement.position.y = 10;
					TweenMax.to(brickElement.position, Math.random() * 0.2 + 0.2, {
						delay: Math.random() * 0.3 + 2,
						y: 0, onStart: this.showBrick, onStartParams: [brickElement]
					});

					this.gameBoardInnerContainer.add(brickElement);
					this.bricks.push(brickClass);

					var brickBounds = {
						left: brickElement.position.x,
						top: brickElement.position.z,
						right: brickElement.position.x + Globals.gameVariables.brickWidth - Globals.gameVariables.brickMargin,
						bottom: brickElement.position.z + Globals.gameVariables.brickHeight - Globals.gameVariables.brickMargin
					};
					brickClass.storeBrickBounds(brickBounds);
				}
				xPosCount++;
			}
			rowCount++;
			xPos = startXPos;
			xPosCount = 0;
		}
	};

	private showBrick = (brick) => {
		brick.visible = true;
	}

	private keyDown = (event: KeyboardEvent) => {
		var key = event.code;
		if (key === 'ArrowLeft') {
			this.keyArrowLeftDown = true;
		} else if (key === 'ArrowRight') {
			this.keyArrowRightDown = true;
		} else if (key === 'KeyA') {
			this.allowBallToMove = true;
		} else if (key === 'KeyB') {
			Globals.gameController.headBangEffect();
		}
	};

	private keyUp = (event: KeyboardEvent) => {
		var key = event.code;
		if (key === 'Space') {
			if (this.gameState === 'waiting') {
				this.startGame();
			}
		}
		if (key === 'ArrowLeft') {
			this.keyArrowLeftDown = false;
		} else if (key === 'ArrowRight') {
			this.keyArrowRightDown = false;
		} else if (key === 'KeyA') {
			this.allowBallToMove = false;
		} else if (key === 'KeyN') {
			this.exitLevel();
		}
	};

	private moveBall = () => {
		if (this.gamePaused === false && this.allowBallToMove === true) {
			this.ballElement.position.x = this.ballElement.position.x + this.ball_dx;
			this.ballElement.position.z = this.ballElement.position.z + this.ball_dy;


			// Check if we should move the ball downwards on its Y pos
			if (this.hasPaddleBeenHit === false && this.gameState === 'playing') {
				//	TweenMax.to(this.ballElement.position, 0.7, {delay: 0.3, y: this.ballClass.getBallSize() + 0.03, ease: Linear.easeNone});
				var distanceToBottom = this.ballElement.position.z / Globals.gameVariables.gameMapHeight;
				this.ballElement.position.y = (0.25 * (1 - distanceToBottom)) + (this.ballClass.getBallSize() + 0.03) * distanceToBottom;
			} else {
				this.ballElement.position.y = this.ballClass.getBallSize() + 0.03;
			}

		} else if (this.gameState === 'waiting') {
			//		this.ballElement.position.x = this.playerElement.position.x - this.ballClass.getBallSize() / 4;
			//		this.ballElement.position.z = this.playerElement.position.z - this.ballClass.getBallSize() - 0.07 / 2;

			if (Globals.faceMouthOpenPercentage) {
				if (Globals.faceMouthOpenPercentage >= 1) {
					//	this.startGame();
				}
			}
		}
	};


	private checkBall_BrickCollision() {

		if (this.hasPaddleBeenHit) {

			var ball = this.ballElement.position;
			var ballHalfSize = this.ballClass.getBallSize();
			var ballBounds = {
				x: ball.x - ballHalfSize,
				y: ball.z - ballHalfSize,
				right: ball.x + ballHalfSize,
				bottom: ball.z + ballHalfSize
			};
			var bricks = this.bricks;
			var _length = bricks.length;

			for (var i = 0; i < _length; i++) {
				var brickClass: Brick = bricks[i];
				var currentBrick: THREE.Group = brickClass.getElement();
				var brickBounds: object = brickClass.getBounds();

				//var brickArray = [brickBounds.left, brickBounds.top, brickBounds.right, brickBounds.top, brickBounds.right, brickBounds.bottom, brickBounds.left, brickBounds.bottom]
				/*var checBrick1 = PolyK.ContainsPoint(brickArray, ballBounds.x, ballBounds.y);
                var checBrick2 = PolyK.ContainsPoint(brickArray, ballBounds.right, ballBounds.y);
                var checBrick3 = PolyK.ContainsPoint(brickArray, ballBounds.right, ballBounds.bottom);
                var checBrick4 = PolyK.ContainsPoint(brickArray, ballBounds.x, ballBounds.bottom); */


				if (ball.z + ballHalfSize > brickBounds.top && ball.z - ballHalfSize < brickBounds.bottom
					&& ball.x + ballHalfSize > brickBounds.left && ball.x - ballHalfSize < brickBounds.right) {
					// Choose which side of the box is closest to the circle's centre
					var dists = [Math.abs(ball.x - brickBounds.left),
						Math.abs(ball.x - brickBounds.right),
						Math.abs(ball.z - brickBounds.top),
						Math.abs(ball.z - brickBounds.bottom)];
					var p = dists.indexOf(Math.min.apply(Math, dists)); // Get minimum value's index in array
					// ... that will be the side that dictates the bounce

					//console.log('p : ' + p);

					if (p < 2) {
						//this.dx = (i || -1) * Math.abs(this.dx);
						//	console.log('sides');
						this.ball_dx = -this.ball_dx;
					} else {
						//this.dy = (i > 2 || -1) * Math.abs(this.dy);

						this.ball_dy = -this.ball_dy;
						//	console.log('up and down');
					}

					var shouldBeRemoved = brickClass.isHit('temp');
					if (shouldBeRemoved === true) {
						bricks.splice(i, 1);
					}

					if (bricks.length === 0) {
						this.bricksRemoved();
					}
					return;
					//}
				}
			}
		}
	}

	private checkBall_BoundsCollision() {
		var ball = this.ballElement.position;
		var x = ball.x - this.ballClass.getBallSize();
		var z = ball.z - this.ballClass.getBallSize();
		var size = this.ballClass.getBallSize();

		if (ball.z - 1.2 > Globals.gameVariables.gameMapHeight) {
			//
			//gameOver = true;
			//winner = false;
			// FIXME - Uncomment to let it bounce on the bottom..
			//this.ball_dy = -this.ball_dy;
			this.gameOver();
		} else if (x + size * 2 > Globals.gameVariables.gameMapWidth) {
			// Hit right
			//ball.x = gameSceneWidth + this.ballClass.getBallSize();
			//ball.x = 0 - this.ballElement.r;
			this.ball_dx = -this.ball_dx;
		} else if (x < 0) {
			// Hit Left
			//ball.x = -gameSceneWidth - this.ballClass.getBallSize();
			//ball.x = 0 + this.ballElement.r;
			this.ball_dx = -this.ball_dx;
		}
		if (ball.z < 0) {
			// Hit Top
			//ball.z = -gameSceneHeight + this.ballClass.getBallSize();
			//this.ballElement.y = -gameSceneHeight + this.ballClass.getBallSize();
			if (this.firstBallRelease !== true && this.allBricksRemoved === false) {
				this.ball_dy = -this.ball_dy;
			} else if (this.allBricksRemoved === true && this.hasPaddleBeenHit === true) {
				// Exit the game
				this.exitLevel();
			}
		} else if (ball.z > 0) {
			// Since the first ball is being shot "off-scene" this allows this - but locks it once the ball is over Z.
			if (this.firstBallRelease === true) {
				this.firstBallRelease = false;
			}
		}
	}


	private checkBall_PlayerCollision() {
		var player = this.playerElement.position;
		var playerWidth = this.playerClass.getWidth();
		var playerXLeft = player.x - playerWidth / 2; // ax1
		var playerXRight = player.x + playerWidth / 2;//player.width; // ax2

		var playerYBottom = player.z + this.playerClass.getDepth() / 2;//player.height; // ay2
		var playerYTop = player.z - this.playerClass.getDepth() / 2; // ay1

		var ball = this.ballElement.position;
		var ballXLeft = ball.x + this.ballClass.getBallSize();
		var ballXRight = ball.x - this.ballClass.getBallSize();

		var ballYTop = ball.z;
		var ballYBottom = ball.z + this.ballClass.getBallSize();

		// Lets check the Y Axis first
		if (ballYTop >= playerYTop && ballYBottom <= playerYBottom && this.ballHitPlayer === false) {
			//	console.log('ON THE LINE')
			//	console.log(ballXLeft, playerXLeft, ballXRight, playerXRight)
			if (ballXLeft >= playerXLeft && ballXRight <= playerXRight) {
				this.ball_dy = -this.ball_dy;


				var howFarWithinThePlayerOnXDidWeHit = (ballXLeft + playerWidth / 2) - playerXLeft;
				/*	console.log('playerXLeft : ' + playerXLeft);
                    console.log('(ballXLeft + playerWidth / 2) : ' + (ballXLeft + playerWidth / 2));
                    console.log('playerWidth : ' + playerWidth);

                    console.log('howFarWithinThePlayerOnXDidWeHit : ' + howFarWithinThePlayerOnXDidWeHit)*/
				var percentageHitOnX = howFarWithinThePlayerOnXDidWeHit * 0.5 / playerWidth;
				//	console.log('percentageHitOnX : ' + percentageHitOnX)
				percentageHitOnX = percentageHitOnX - 0.5;

				//console.log(percentageHitOnX)

				// Adjust this value to make the outshoot angle more aggressive
				percentageHitOnX = percentageHitOnX * 4;
				var angle = percentageHitOnX * -1 + (Math.PI * 0.5);


				var px = this.ballSpeed * Math.cos(angle); // <-- that's the maths you need
				var py = this.ballSpeed * Math.sin(angle);


				//	console.log('px : ' + px);
				//	console.log('py : ' + py);

				if (py > 0) {
					py = py * -1;
				}

				this.ball_dx = (px / 100) * GameLevels.data[Globals.gameVariables.currentLevel].levelBallSpeed;
				this.ball_dy = (py / 100) * GameLevels.data[Globals.gameVariables.currentLevel].levelBallSpeed;


				// FIXME - Sometimes the ball - if it goes over the top y - it starts to bounce multiple times.
				//	console.log('bounce back')

				if (this.hasPaddleBeenHit === false) {

				}
				this.ballHitPlayer = true;
				this.hasPaddleBeenHit = true;
			}
		}

		if (this.ballHitPlayer === true) {
			if (ballYTop < playerYTop) {
				// Reset this so we wont hit the "player" multiple times.
				this.ballHitPlayer = false;
			}
		}
	}

	public animate = () => {

		if (this.allowAnimation === true) {


			this.moveBall();
			if (this.gamePaused === false && this.allowBallToMove === true) {
				this.checkBall_PlayerCollision();
				this.checkBall_BoundsCollision();

				if (this.allBricksRemoved === false) {
					this.checkBall_BrickCollision();
				}
			}

			if (this.keyArrowLeftDown === true) {
				this.playerElement.position.x = this.playerElement.position.x - 0.05;
			} else if (this.keyArrowRightDown === true) {
				this.playerElement.position.x = this.playerElement.position.x + 0.05;
			}
		}
	};

	public updatePlayer(facePercentageOnX: number) {
		if (this.allowAnimation === true) {
			if (facePercentageOnX) {

				//console.log(Globals.faceXPositionSmoothed)
				var newPos = facePercentageOnX - 0.5;
				newPos = newPos * Globals.gameVariables.paddleMoveDistance;
				newPos += Globals.gameVariables.gameMapWidth / 2;
				//this.playerElement.position.x = newPos;
				//this.updatePlayerPosition();

				TweenMax.to(this.playerPosition, 0.3, {
					x: newPos,
					ease: Linear.easeNone,
					onUpdate: this.updatePlayerPosition
				})
				// y: cameraY * -1, z: cameraZ,
				//TweenMax.to(this.gameBoardContainer.rotation, 0.1, {delay: 0.03, y:(newPos / 10) * 1, onComplete: this.startGame});
			}
		}
	}

	public updatePlayerPosition = () => {
		// Set bounds - make sure the player cant move out of its area
		var newPos = this.playerPosition.x;
		var minX = this.playerClass.getWidth() / 2;
		var maxX = Globals.gameVariables.gameMapWidth - this.playerClass.getWidth() / 2;
		if (newPos < minX) {
			newPos = minX;
		} else if (newPos > maxX) {
			newPos = maxX;
		}
		this.playerElement.position.x = newPos;
		//console.log(this.playerElement)
	};

	public headBangEffect = () => {


		if (this.gameState === 'playing') {
			// Shake the gameboard
			TweenMax.to(Globals.skull.getMesh().position, 0.1, {z: 0.3, ease: Power2.easeOut});
			TweenMax.to(Globals.skull.getMesh().position, 0.1, {delay: 0.1, z: -0.4, ease: Power2.easeIn});
			TweenMax.to(Globals.skull.getMesh().rotation, 0.1, {x: THREE.Math.degToRad(30), ease: Power2.easeOut});
			TweenMax.to(Globals.skull.getMesh().rotation, 0.1, {
				delay: 0.1,
				x: THREE.Math.degToRad(0),
				ease: Power2.easeOut
			});


			TweenMax.to(this.gameBoardContainer.position, 0.1, {delay: 0.05, z: 5.5, ease: Power2.easeOut});
			TweenMax.to(this.gameBoardContainer.position, 0.1, {delay: 0.1, z: 5, ease: Power2.easeOut});

			TweenMax.to(Globals.mainScene.getCamera().rotation, 0.1, {delay: 0.05, x:THREE.Math.degToRad(1.8), ease: Power2.easeOut});
			TweenMax.to(Globals.mainScene.getCamera().rotation, 0.1, {delay: 0.15, x: 0, ease: Power2.easeOut});


			var sound = new Howl({
				src: ['assets/sounds/FOS2_Kick_053.mp3']
			});
			sound.seek(0);
			sound.play();



			//@ts-ignore:
			window['ga']('send', 'event', 'Head Movement', 'Head Bang - Had Effect');
			// Remove random brick
			var bricks = this.bricks;
			var _length = bricks.length;

			if (bricks.length === 0) {

			} else {
				var getRandomBrick = Math.floor(Math.random() * (_length - 1));
				var currentBrick = bricks[getRandomBrick];
				var shouldBeRemoved = currentBrick.isHit('temp', true, 'bang');
				if (shouldBeRemoved === true) {
					bricks.splice(getRandomBrick, 1);
				}

				if (bricks.length === 0) {
					this.bricksRemoved();
				}

			}
		} else {
			// Do a "no" movement
			TweenMax.to(Globals.skull.getMesh().rotation, 0.1, {
				y: THREE.Math.degToRad(30),
				ease: Power2.easeInOut
			});
			TweenMax.to(Globals.skull.getMesh().rotation, 0.1, {
				delay: 0.1,
				y: THREE.Math.degToRad(-30),
				ease: Power2.easeInOut
			});
			TweenMax.to(Globals.skull.getMesh().rotation, 0.1, {
				delay: 0.2,
				y: THREE.Math.degToRad(0),
				ease: Power2.easeInOut
			});
		}
	};


	public getGameBoard() {
		return this.gameBoardContainer;
	}
}
